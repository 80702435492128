import * as React from 'react';
import classNames from 'classnames';
import { InjectedEntryProps, withEntry } from '../@context/Entry';
import { withApi, InjectedApiProps } from '../@context/Api';
import {
  Grid,
  Paper,
  Typography,
  StyledComponentProps,
  withStyles,
  Button,
} from '@material-ui/core';
import { withFields, InjectedFieldsProps } from '../@context/Fields';
import Field from './Field';
import { StyleRules } from '@material-ui/core/styles';
import { fontSize, underline } from '../constants/stylesheet';
import EntryIcon from './EntryIcon';
import InvoiceUtilities from './ServiceUtilities/InvoiceUtilities';
import SubscriptionUtilities from './ServiceUtilities/SubscriptionUtilities';
import ClientUtilities from './ServiceUtilities/ClientUtilities';
import EmailShipmentUtilities from './ServiceUtilities/EmailShipmentUtilities';
import { GridSize } from '@material-ui/core/Grid';
import OfferUtilities from './ServiceUtilities/OfferUtilities';

const LOCALSTORAGE_KEY = 'ourservers.ch_DetailKey';
const styles = {
  container: {
    position: 'relative',
    height: '100%',
    pointerEvents: 'initial',
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    padding: `0 ${16 / fontSize}rem`,
    boxSizing: 'border-box',
  },
  contentInner: {
    display: 'flex',
    paddingBottom: 60,
    '@media screen and (max-width: 1000px)': {
      '&': {
        flexDirection: 'column',
      },
    },
    '@media screen and (min-width: 1001px)': {
      '&': {
        flexDirection: 'row',
      },
    },
  },
  contentItem: {
    '@media screen and (max-width: 1000px)': {
      '&': {
        width: '100%',
        padding: 16,
        boxSizing: 'border-box',
      },
    },
    '@media screen and (min-width: 1001px)': {
      '&': {
        width: '50%',
        padding: 16,
        boxSizing: 'border-box',
      },
    },
  },
  gridItem: {},
  header: {
    display: 'flex',
    padding: '0 16px',
  },
  title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  titleIcon: {
    marginRight: 10,
    lineHeight: 0,
  },
  titleType: {
    marginRight: 10,
    position: 'relative',
    '&:after': underline,
  },
  list: {
    padding: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  gridItemOutside: {
    padding: 32,
  },
  actionsContainer: {
    position: 'absolute',
    right: 20,
    bottom: 20,
  },
  backButton: {},
  discardButton: {
    marginLeft: 10,
    boxShadow: 'none',
  },
  deleteButton: {
    marginLeft: 10,
    boxShadow: 'none',
    backgroundColor: 'rgb(255, 100, 100)',
    color: 'white',

    '&:hover': {
      backgroundColor: 'rgb(255, 0, 0)',
    },
  },
  saveButton: {
    marginLeft: 10,
    boxShadow: 'none',
    backgroundColor: 'rgb(100, 200, 100)',
    color: 'white',

    '&:hover': {
      backgroundColor: 'rgb(0, 150, 0)',
    },
  },
  markAsPaidBtnContainer: {
    marginLeft: 10,
    display: 'inline-block',
    '& > *': {
      boxShadow: 'none',
    },
  },
};

type DetailsProps = {
  entry: EntryDto;
  path: string;
};

type Props = DetailsProps &
  InjectedEntryProps &
  InjectedApiProps &
  InjectedFieldsProps &
  StyledComponentProps<keyof typeof styles>;

type State = {
  current?: EntryDto;
  changes: Partial<EntryDto>;
  fields?: FieldDto[];
  lastUpdate?: string;
};
class Detail extends React.Component<Props, State> {
  private saveId: NodeJS.Timeout | undefined;
  readonly state: State = {
    changes: {},
  };

  constructor(props: Props) {
    super(props);

    this._close = this._close.bind(this);
    this._save = this._save.bind(this);
    this._delete = this._delete.bind(this);
    this._saveLocalChanges = this._saveLocalChanges.bind(this);
    this._resetLocalChanges = this._resetLocalChanges.bind(this);
    this._getLocalChanges = this._getLocalChanges.bind(this);
    this._refresh = this._refresh.bind(this);
    const { entry } = this.props;
    this.state = { current: entry, changes: this._getLocalChanges() };
  }
  async componentDidMount() {
    const {
      props: {
        fieldsCtx: { get: getFields },
        entry: { Type },
      },
    } = this;
    const fields = await getFields(Type);
    this.setState(() => ({ fields }));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      entry: { Type, Id },
      apiCtx: { lastChange },
    } = this.props;
    if (lastChange && this.state.lastUpdate !== lastChange.Date) {
      // do we need to update ?
      if (lastChange.Modified.some((m) => m.Id === Id && m.Type === Type)) {
        this._refresh();
      }
      this.setState(() => ({ lastUpdate: lastChange.Date }));
    }
    if (this.saveId) {
      clearTimeout(this.saveId);
    }
    this.saveId = setTimeout(this._saveLocalChanges, 500);
  }
  render() {
    const {
      props,
      state: { current, fields },
    } = this;

    if (!current || !fields) {
      return null;
    }

    const {
      path,
      classes,
      entryCtx: { close },
      entry: { Id, Type },
    } = props;

    const setChanges = (changes: Partial<EntryDto>) =>
      new Promise<void>((resolve, reject) =>
        this.setState(() => ({ changes }), resolve)
      );

    const preview = { ...current, ...this.state.changes } as EntryDto;
    const gridSpacing = 2;
    const discard = () => {
      this._resetLocalChanges();
      close(path);
    };

    return (
      <div className={classes!.container}>
        <div className={classes!.content}>
          <div className={classes!.header}>
            <Typography variant='h1' className={classes!.title}>
              <a
                className={classes!.titleIcon}
                title={current.PreviewDescription}
                href='#'
              >
                <EntryIcon entry={current} />
              </a>
              <span className={classes!.titleType}>{current.Type}</span>
              <span> {current.PreviewTitle}</span>
            </Typography>
          </div>
          <div className={classes!.contentInner}>
            <div className={classes!.contentItem}>
              <Grid container={true} spacing={gridSpacing}>
                <Grid className={classes!.gridItem} item={true} xs={12}>
                  <Paper elevation={2}>
                    <div style={{ padding: gridSpacing * 8 }}>
                      <Grid
                        container={true}
                        className='gridContainer'
                        spacing={gridSpacing}
                      >
                        {fields
                          .filter((f) => f.Group === 'Inside')
                          .sort((f1, f2) => f1.Order - f2.Order)
                          .map((f, i) => (
                            <React.Fragment key={i}>
                              <Grid
                                key={i}
                                className={classes!.gridItem}
                                style={
                                  (f.OffsetUnder && {
                                    paddingBottom: f.OffsetUnder * 8,
                                  }) ||
                                  undefined
                                }
                                item={true}
                                xs={f.Width as GridSize}
                              >
                                <Field
                                  preview={preview}
                                  field={f}
                                  type={Type}
                                  id={Id}
                                  path={path}
                                  changes={this.state.changes}
                                  setChanges={setChanges}
                                  refresh={this._refresh}
                                />
                              </Grid>
                              {(f.OffsetAfter && (
                                <Grid
                                  className={classes!.gridItem}
                                  item={true}
                                  xs={f.OffsetAfter as GridSize}
                                />
                              )) ||
                                undefined}
                            </React.Fragment>
                          ))}
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
                {fields
                  .filter((f) => f.Group === 'Outside')
                  .map((f, i) => (
                    <Grid
                      key={i}
                      className={classNames(
                        classes!.gridItem,
                        classes!.gridItemOutside
                      )}
                      item={true}
                      xs={f.Width as GridSize}
                    >
                      <Field
                        preview={preview}
                        field={f}
                        type={Type}
                        id={Id}
                        path={path}
                        changes={this.state.changes}
                        setChanges={setChanges}
                        refresh={this._refresh}
                      />
                    </Grid>
                  ))}
                {props.children}
              </Grid>
            </div>
            <div className={classes!.contentItem}>
              {this._renderUtilities(preview, this.state.changes)}
            </div>
          </div>
        </div>
        <div className={classes!.actionsContainer}>
          <Button
            className={classes!.discardButton}
            onClick={discard}
            size='small'
            variant='contained'
          >
            {Object.keys(this.state.changes).length > 0 ? 'discard' : 'back'}
          </Button>
          <Button
            className={classes!.deleteButton}
            onClick={this._delete}
            size='small'
            variant='contained'
          >
            delete
          </Button>

          {Object.keys(this.state.changes).length > 0 ? (
            <Button
              className={classes!.saveButton}
              variant='contained'
              onClick={this._save}
              size='small'
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
  private _saveLocalChanges() {
    localStorage.setItem(
      `${LOCALSTORAGE_KEY}.${this.props.entry.Id}`,
      JSON.stringify(this.state.changes)
    );
  }
  private _resetLocalChanges() {
    localStorage.removeItem(`${LOCALSTORAGE_KEY}.${this.props.entry.Id}`);
  }

  private _getLocalChanges() {
    const saved = localStorage.getItem(
      `${LOCALSTORAGE_KEY}.${this.props.entry.Id}`
    );
    return saved ? JSON.parse(saved) : {};
  }
  private _close() {
    const {
      path,
      entryCtx: { close },
    } = this.props;
    close(path);
  }

  private async _save() {
    const { changes } = this.state;
    if (!changes) {
      return;
    }

    this._resetLocalChanges();

    const {
      apiCtx: { save },
      entry: { Id, Type },
    } = this.props;
    const current = await save(Type, Id, changes);
    this.setState(() => ({ current, changes: {} }));
    // close(path);
  }

  private async _delete() {
    const { changes } = this.state;
    if (changes) {
      this._resetLocalChanges();
    }

    const {
      path,
      entryCtx: { close },
      entry: { Id, Type },
      apiCtx: { deleteEntry },
    } = this.props;
    await deleteEntry(Type, Id);
    close(path);
  }

  private async _refresh() {
    const {
      props: {
        apiCtx: { preview },
        entry: { Id, Type },
      },
    } = this;

    const {
      state: { changes },
    } = this;

    const current = await preview(Type, Id, changes);
    this.setState(() => ({ current }));
  }

  private _renderUtilities(preview: EntryDto, changes: Partial<EntryDto>) {
    const {
      entry: { Type },
      path,
    } = this.props;
    switch (Type) {
      // case 'Website':
      //     return <WebsiteUtilities entry={preview as Website} />;
      case 'Client':
        return <ClientUtilities entry={preview as Client} />;
      case 'Invoice':
        return (
          <InvoiceUtilities
            entry={preview as Invoice}
            changes={changes}
            refresh={this._refresh}
            path={path}
          />
        );
      case 'Offer':
        return (
          <OfferUtilities
            entry={preview as Offer}
            changes={changes}
            refresh={this._refresh}
            path={path}
          />
        );
      case 'EmailShipment':
        return (
          <EmailShipmentUtilities
            entry={preview as EmailShipment}
            changes={this.state.changes}
            refresh={this._refresh}
          />
        );
      case 'Subscription':
        if (Object.keys(changes).length > 0) {
          return;
        }
        return (
          <SubscriptionUtilities
            entry={preview as Subscription}
            refresh={this._refresh}
          />
        );
      default:
        return null;
    }
  }
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(
  withApi(withEntry(withFields(Detail)))
);
