import * as React from 'react';
import anime, { AnimInstance } from 'animejs';
import { withEntry, InjectedEntryProps } from '../@context/Entry';
import ListViewItems from './ListViewItems';
import Detail from './Detail';
import {
  StyledComponentProps,
  withStyles,
  Typography,
  TextField,
} from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import classNames from 'classnames';
import { fontSize, underline } from '../constants/stylesheet';
import { throttle, Cancelable } from 'lodash';

type ListViewProps = {
  type: string;
  itemRenderer: (entry: IEntry) => React.ReactNode;
};

const styles = {
  container: {
    position: 'relative',
    height: '100%',
  },
  rail: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'visible',
  },
  railItem: {
    position: 'relative',
    flex: '0 0 100%',
    height: '100%',
  },
  list: {
    padding: `0 ${16 / fontSize}rem`,
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    display: 'flex',
    padding: `0 ${16 / fontSize}rem`,
  },
  content: {
    position: 'relative',
    flex: '0 1 100%',
  },
  searchLegend: {
    opacity: 1,
    pointerEvents: 'initial',
    transition: 'opacity .2s ease',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  searchUnderline: {
    '&:after': underline,
  },
  underline: {
    '&:before, &:after': {
      borderBottomColor: 'black',
    },
  },
  hideTitle: {
    opacity: 0,
    pointerEvents: 'none',
    zIndex: -1,
  },
  searchInput: {
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity .3s ease',
  },
  searchInputUnderline: {
    '&:before, &:after': {
      borderBottomColor: 'black',
    },
  },
  showSearch: {
    opacity: 1,
    pointerEvents: 'initial',
  },
  filter: {
    paddingRight: `${16 / fontSize}rem`,
  },
  filterLink: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover:after': underline,
    color: 'rgb(180, 180, 180)',
  },
  filterActive: {
    '&$filterLink:after': underline,
    color: 'black',
  },
  search: {
    position: 'relative',
    flex: '0 0 150px',
    width: '150px',
    transition: 'width .2s linear',
    color: 'rgb(180, 180, 180)',

    '&::placeholder': {
      color: 'rgb(180, 180, 180)',
    }
  },
  searchActive: {
    flex: '0 0 300px',
    width: '300px',
    color: 'black',
  },
  searchQuery: {
    position: 'relative',
    color: 'rgb(180, 180, 180)',
  },
};

const filters = [
  {
    type: 'invoice',
    filters: [
      { legend: 'Due', query: 'State:Due' },
      { legend: 'OverDue', query: 'State:OverDue' },
      { legend: 'Paid', query: 'State:Paid' },
    ],
  },
  {
    type: 'website',
    filters: [
      { legend: 'Loading', query: 'State:Loading' },
      { legend: 'Ok', query: 'State:Ok' },
      { legend: 'Alert', query: 'State:Alert' },
      { legend: 'Error', query: 'State:Error' },
    ],
  },
  {
    type: 'domainName',
    filters: [
      { legend: 'Loading', query: 'State:Loading' },
      { legend: 'Ok', query: 'State:Ok' },
      { legend: 'Alert', query: 'State:Alert' },
      { legend: 'Error', query: 'State:Error' },
    ],
  },
  {
    type: 'certificate',
    filters: [
      { legend: 'Loading', query: 'State:Loading' },
      { legend: 'Ok', query: 'State:Ok' },
      { legend: 'Alert', query: 'State:Alert' },
      { legend: 'Error', query: 'State:Error' },
    ],
  },
  {
    type: 'subscription',
    filters: [
      { legend: 'Init', query: 'State:Init' },
      { legend: 'Ok', query: 'State:Ok' },
      { legend: 'PendingPayment', query: 'State:PendingPayment' },
      { legend: 'Revoked', query: 'State:Revoked' },
      { legend: 'Done', query: 'State:Done' },
    ],
  },
];

type Props = ListViewProps &
  InjectedEntryProps &
  StyledComponentProps<keyof typeof styles>;

type State = {
  query?: string;
  searchQuery?: string;
  showTitle: boolean;
  details: Array<{
    path: string;
    entry: EntryDto;
  }>;
};
class ListView extends React.Component<Props, State> {
  readonly state: State = { showTitle: true, details: [] };

  private rail = React.createRef<HTMLDivElement>();
  private input = React.createRef<HTMLInputElement>();
  private animation: AnimInstance | undefined;
  private setQueryThrottled: (((q: string) => void) & Cancelable) | undefined;
  componentDidUpdate(oldProps: Props, oldState: State) {
    const {
      entryCtx: { details: newDetails },
    } = this.props;
    const { details: oldDetails } = this.state;
    const { length: newLength } = newDetails;
    const { length: oldLength } = oldDetails;
    const changed =
      newDetails.map((d) => d.path).join('/') !==
      oldDetails.map((d) => d.path).join('/');

    if (changed) {
      if (this.rail.current) {
        if (oldLength <= newLength) {
          this.updateDetailsState();
        }
        if (this.animation) {
          this.animation.pause();
        }
        this.animation = anime({
          targets: this.rail.current,
          translateX: `-${this.props.entryCtx.details.length * 100}%`,
          duration: 800,
          easing: 'easeInOutSine',
        });
        if (oldLength > newLength) {
          this.animation.finished.then(() => {
            this.updateDetailsState();
          });
        }
      }
    }
  }
  componentDidMount() {
    const setQuery = (q: string) => {
      this.setState({ query: q });
    };

    this.setQueryThrottled = throttle(setQuery, 1000);
  }

  render() {
    const {
      props: { children, classes, itemRenderer, type },
      state,
    } = this;
    const { query, showTitle, searchQuery } = state;
    const { details } = this.state;
    const filtersToDisplay = filters.find((f) => f.type === type);

    const searchChanged = (q: string) => {
      this.setState({ searchQuery: q });
      this.setQueryThrottled!(q);
    };

    return (
      <div className={classes!.container}>
        <div ref={this.rail} className={classes!.rail}>
          <div className={classNames(classes!.railItem, classes!.list)}>
            <div className={classes!.header}>
              {filtersToDisplay &&
                filtersToDisplay.filters.map((f) => (
                  <div
                    key={f.query}
                    className={classes!.filter}
                    onClick={(e) =>
                      this.setState((prev) =>
                        prev.query === f.query
                          ? { query: undefined }
                          : { query: f.query, searchQuery: undefined }
                      )
                    }
                  >
                    <Typography
                      variant='body1'
                      className={
                        // tslint:disable-next-line:max-line-length
                        classNames(
                          classes!.filterLink,
                          query === f.query && classes!.filterActive
                        )
                      }
                    >
                      {f.legend}
                    </Typography>
                  </div>
                ))}
              <div
                className={classNames(
                  classes!.search,
                  !showTitle && classes!.searchActive
                )}
              >
                <Typography
                  className={classNames(
                    classes!.searchLegend,
                    !showTitle && classes!.hideTitle
                  )}
                  variant='body1'
                  onClick={() =>
                    this.input.current && this.input.current.focus()
                  }
                >
                  <span
                    // tslint:disable-next-line:max-line-length
                    className={classNames(
                      classes!.searchQuery,
                      searchQuery && classes!.searchUnderline
                    )}
                  >
                    {searchQuery ? `Search: ${searchQuery}` : 'Search'}
                  </span>
                </Typography>
                <TextField
                  fullWidth={true}
                  className={classNames(
                    classes!.searchInput,
                    !showTitle && classes!.showSearch
                  )}
                  inputRef={this.input}
                  InputProps={{
                    classes: { underline: classes!.underline },
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                    onBlur: () => this.setState({ showTitle: true }),
                    onFocus: () => this.setState({ showTitle: false }),
                  }}
                  placeholder={searchQuery}
                  onChange={(e) => searchChanged(e.target.value)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            </div>
            <div className={classes!.content}>
              <ListViewItems
                itemRenderer={itemRenderer}
                type={type}
                query={query}
              />
            </div>
            {children}
          </div>
          {details.map(({ path, entry }) => (
            <div className={classes!.railItem} key={path}>
              <Detail
                {...{
                  path,
                  entry,
                }}
              />
            </div>
          ))}
        </div>
      </div >
    );
  }
  private updateDetailsState() {
    this.setState(() => ({
      details: this.props.entryCtx.details,
    }));
  }
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(
  withEntry(ListView)
);
