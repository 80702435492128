import * as React from 'react';
import { withStyles, WithStyles, createMuiTheme } from '@material-ui/core';
import { ApiProvider } from './@context/Api';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { StyleRules } from '@material-ui/core/styles/withStyles';
import HashRouter from './components/HashRouter';
import { FieldsProvider } from './@context/Fields';
import 'typeface-open-sans';
import { fontSize } from './constants/stylesheet';
import { ThemeProvider } from '@material-ui/styles';
import Pay from './pages/Pay';
declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		neutral: Palette['primary'];
	}
	interface PaletteOptions {
		neutral: PaletteOptions['primary'];
	}
}

export type AppProps = {};

const theme = createMuiTheme({
	typography: {
		h1: {
			fontSize: '1.5rem',
			fontWeight: 600,
			lineHeight: '1.2',
		},
		h2: {
			fontSize: '1rem',
			fontWeight: 600,
			lineHeight: '1.2',
		},
		body1: {
			fontWeight: 400,
			color: 'black',
		},
		body2: {
			fontWeight: 400,
		},
		fontFamily: 'circular',
		// fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
	},
	palette: {
		primary: {
			light: 'rgb(170, 220, 255)',
			main: 'rgb(100, 180, 255)',
			dark: 'rgb(0, 150, 255)',
			contrastText: 'white',
		},
		secondary: {
			light: 'rgb(255, 200, 255)',
			main: 'rgb(255, 100, 255)',
			dark: 'rgb(255, 0, 255)',
			contrastText: 'white',
		},
		error: {
			light: 'rgb(255, 200, 200)',
			main: 'rgb(255, 100, 100)',
			dark: 'rgb(255, 0, 0)',
			contrastText: 'white',
		},
		warning: {
			light: 'rgb(255, 230, 150)',
			main: 'rgb(255, 200, 100)',
			dark: 'rgb(255, 150, 0)',
			contrastText: 'white',
		},
		success: {
			light: 'rgb(150, 220, 150)',
			main: 'rgb(100, 200, 100)',
			dark: 'rgb(0, 150, 0)',
			contrastText: 'white',
		},
		neutral: {
			light: 'rgb(230, 230, 230)',
			main: 'rgb(180, 180, 180)',
			dark: 'rgb(150, 150, 150)',
		},
	},
});

const styles = {
	'@global': {
		'html, body': {
			position: 'relative',
			fontSize: fontSize,
			width: '100%',
			height: '100%',
			margin: 0,
			padding: 0,
		},
		'html.no-cursor *': {
			cursor: 'none !important',
			pointerEvents: 'none',
		},
		a: {
			textDecoration: 'none',
			color: 'inherit',
			cursor: 'pointer',
			'&:hover': {
				color: 'black',
			},
		},
		'#root': {
			position: 'absolute',
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			top: 0,
			left: 0,
			background: 'white',
		},
	},
};

type Props = AppProps & WithStyles<keyof typeof styles>;
export const API_URL = '/api/1.0';
class App extends React.Component<Props> {
	public render() {
		return (
			<ApiProvider apiUrl={API_URL}>
				<ThemeProvider theme={theme}>
					<FieldsProvider>
						<Router>
							<Switch>
								<Route path={'/Pay'} exact={true} component={Pay} />
								<HashRouter />
							</Switch>
						</Router>
					</FieldsProvider>
				</ThemeProvider>
			</ApiProvider>
		);
	}
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(App);
