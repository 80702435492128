import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { InjectedApiProps, withApi } from '../../@context/Api';
import { withEntry, InjectedEntryProps } from '../../@context/Entry';
import EntryFieldItem from './EntryFieldItem';
import { List, Typography } from '@material-ui/core';
import EntryFieldSelect from './EntryFieldSelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
            width: '100%',
            paddingTop: 0,
            paddingBottom: 0
        },
        input: {
            flex: 1,
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            flexBasis: 200,
        },
        iconButton: {
            padding: '10px 10px 10px 0',
        },
        header: {},
        headerTitle: {
            fontWeight: 600
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
    }),
);

type EntryFieldProps = {
    field: FieldDto;
    type: string;
    id: string;
    path: string;
    value: EntryDto;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => Promise<void>;
    refresh: () => void;
};

type Props = EntryFieldProps & InjectedApiProps & InjectedEntryProps;

const EntryField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const {
        value, field, changes, setChanges, refresh, path,
        id, type,
        apiCtx: { create, getAll }
    } = props;

    const remove = async () => {
        await setChanges({
            ...changes,
            [field.NavigationProperty]: undefined,
            [field.PropertyName]: undefined
        });
        refresh();
    };
    
    const _change = async (e?: EntryDto) => {
        await setChanges({
            ...changes,
            [field.NavigationProperty]: e && e.Id,
            [field.PropertyName]: e
        });
        refresh();
    };

    const _create = async (query?: string) => {
        var created = await create(field.ConcreteTypes[0], { Id: id, Type: type }, query || '');

        await setChanges({
            ...changes,
            [field.NavigationProperty]: created.Id,
            [field.PropertyName]: created
        });

        refresh();
    };

    const _list = (query: string) =>
        getAll(field.PropertyType, query);

    return (
        <>
            <div className={classes.header}>
                <Typography className={classes.headerTitle}>
                    {field.Legend}
                </Typography>
            </div>
            <List dense={true} className={classes.root} component='div'>
                {value && (
                    <EntryFieldItem 
                        path={path} 
                        field={field} 
                        remove={remove} 
                        value={value} 
                    />
                )}
                {!value && 
                    <EntryFieldSelect path={path} field={field} onCreate={_create} change={_change} list={_list} />}
            </List>
        </>
    );
};

export default withEntry(withApi(EntryField));