import * as React from 'react';
import { useApi } from '../../@context/Api';
import { Grid, Button, makeStyles, createStyles } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            padding: 24
        },
        btnAdd: {

        }
    }));

type SubscriptionUtilitiesProps = {
    entry: Subscription;
    refresh: () => void;
};

type Props =
    SubscriptionUtilitiesProps;

const SubscriptionUtilities: FC<Props> = ({ children, refresh, entry: { State, BillingPeriod, Id } }) => {
    const classes = useStyles({});
    const { fetch } = useApi();
    const renew = () => {
        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch<Invoice>(`subscription/${Id}/renew`, options).then(
            () => refresh()
        );
    };

    return (
        <div className={classes.container}>
            <Grid
                container={true}
                spacing={6}
            >
                {BillingPeriod !== 'None' && (State === 'Init') && (
                    <Button
                        className={classes.btnAdd}
                        aria-label='Renew'
                        onClick={renew}
                        color='primary'
                        variant='contained'
                        size='small'
                    >
                        Start subscription
                    </Button>
                )}
                {BillingPeriod !== 'None' && State !== 'Init' && (
                    <Button
                        className={classes.btnAdd}
                        aria-label='Renew'
                        onClick={renew}
                        color='primary'
                        variant='contained'
                        size='small'
                    >
                        Renew
                    </Button>
                )}
                {children}
            </Grid>
        </div>
    );
};

export default SubscriptionUtilities;