import * as React from 'react';
import { Subtract } from 'utility-types';
import { InjectedApiProps, withApi } from './Api';

type Fields = {
    fetching: boolean;
    get: (type: string) => Promise<FieldDto[]>;
};

const initValue: Fields = {
    fetching: true,
    get: 0 as never
};

const FieldsReactContext = React.createContext<Fields>(initValue);

type FieldsProviderProps = InjectedApiProps & {};
class FieldsProviderApi extends React.Component<FieldsProviderProps, Fields> {
    private cache: { [type: string]: FieldDto[] } = {};
    public constructor(props: FieldsProviderProps) {
        super(props);

        this.state = {
            fetching: true,
            get: this._get.bind(this),

        };
    }

    render() {
        return (
            <FieldsReactContext.Provider value={this.state}>
                {this.props.children}
            </FieldsReactContext.Provider>
        );
    }

    private async _get(type: string) {
        if (this.cache[type]) {
            return this.cache[type];
        }
        const { apiCtx: { fetch } } = this.props;
        await this.setStateAsync({ fetching: true });
        var fields = await fetch<FieldDto[]>(`fields/${type}`, { method: 'GET' });
        this.cache[type] = fields;

        return fields;
    }

    setStateAsync(state: Partial<Fields>) {
        return new Promise((resolve) => {
            this.setState(state as Fields, resolve);
        });
    }

}

export const FieldsProvider = withApi(FieldsProviderApi);

export type InjectedFieldsProps = { fieldsCtx: Fields };
export const withFields =
    <OriginalProps extends InjectedFieldsProps>(
        Component: React.ComponentType<OriginalProps>
    ): React.FunctionComponent<Subtract<OriginalProps, InjectedFieldsProps>> => {

        return (props: Subtract<OriginalProps, InjectedFieldsProps>) => {
            return (
                <FieldsReactContext.Consumer>
                    {(fieldsCtx) => <Component {...{ ...(props as object), fieldsCtx } as OriginalProps} />}
                </FieldsReactContext.Consumer>
            );
        };
    };