import * as React from 'react';
import { Route } from 'react-router-dom';
import withStyles, { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import ListContent from '../components/ListContent';
import Menu, { Menus } from '../components/Menu';
import { InjectedApiProps, withApi } from '../@context/Api';
import Loading from '../components/Loading';

export type HomeProps = {};

const styles = {
  app: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    top: 0,
    left: 0,
    background: 'white'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  menu: {
    flex: ' 0 0 auto',
  },
  contentOuter: {
    position: 'relative',
    flex: '1 1 auto'
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  }
};

type Props = HomeProps & InjectedApiProps &
  WithStyles<keyof typeof styles>;

class Home extends React.Component<Props> {
  public render() {
    const { classes, apiCtx: { fetching } } = this.props;

    return (
      <div className={classes!.app}>
        {!!fetching && <Loading />}

        <div className={classes!.container}>
          <div className={classes!.menu}>
            <Menu />
          </div>
          <div className={classes!.contentOuter}>
            <div className={classes!.content}>
              {
                Menus.map(m => {
                  return (
                    <Route
                      key={m.path}
                      path={m.path}
                      render={() => {
                        return (
                          <>
                            {m.list && <ListContent key={m.path} type={m.list} baseURL={m.path} />}
                            {m.children && m.children.filter(c => c.list).map(c => (
                              <Route
                                key={c.path}
                                path={c.path}
                                render={() => <ListContent key={c.path} type={c.list!} baseURL={c.path} />}
                              />
                            ))}
                          </>
                        );
                      }}
                    />
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(withApi(Home));
