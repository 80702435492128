import * as React from 'react';
import { ApiContext } from '../@context/Api';
import { EntryContext } from '../@context/Entry';
import { DialogTitle, DialogContent, Grid, DialogActions, Button, Typography } from '@material-ui/core';
import Field from './Field';

const ProductDetail: React.FunctionComponent<{ type: string; close: () => void; }> = ({ type, close }) => {
    const [fields, setFields] = React.useState<FieldDto[]>([]);
    const [current, setCurrent] = React.useState<EntryDto>({} as EntryDto);

    const [changes, setChanges] = React.useState<Partial<EntryDto>>({});
    const { fetch } = React.useContext(ApiContext);
    const { open } = React.useContext(EntryContext);
    React.useEffect(
        () => {
            fetch<{ fields: FieldDto[], entry: EntryDto }>(`products/${type}`, { method: 'GET' })
                .then(({ fields: getFields, entry }) => {
                    setFields(getFields);
                    setCurrent(entry);
                });
        },
        []
    );
    const preview = { ...current, ...changes };
    const refresh = () => Promise.resolve();
    const setChangesField = (chgs: Partial<EntryDto>) =>
        new Promise<void>((resolve) => { setChanges(chgs); resolve(); });
    const save = async () => {

        const patchDocument = Object.keys(changes).map(k => ({ op: 'replace', path: `/${k}`, value: changes[k] }));
        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patchDocument)
        };

        const result = await fetch<Subscription>(`products/${type}`, options);
        await open(result.Id, result.Id, result.Type);
        close();
    };
    return (
        <>
            <DialogTitle disableTypography={true}>
                <Typography variant='h1'>
                    Create {type}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container={true}
                    spacing={3}
                >
                    {fields.map((field, i) => (
                        <Grid
                            key={i}
                            item={true}
                            xs={12}
                        >
                            <Field
                                {...{
                                    field,
                                    path: '',
                                    type,
                                    id: '',
                                    key: field.PropertyName,
                                    changes,
                                    preview: preview,
                                    setChanges: setChangesField,
                                    refresh
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={save}>Save</Button>
            </DialogActions>
        </>
    );
};

export default ProductDetail;