// import { LOCALSTORAGE_KEY } from '../_context';

// export const addAuthHeader = (requestOptions: RequestInit) => {
//     // return authorization header with jwt token
//     const localUser = localStorage.getItem(LOCALSTORAGE_KEY);
//     if (!localUser) { return requestOptions; }

//     const user = JSON.parse(localUser) as Client;

//     if (!user || !user.Token) { return requestOptions; }
//     requestOptions.headers = { ...requestOptions.headers, 'Authorization': 'Bearer ' + user.Token };
//     return requestOptions;
// };

export const handleApiResponse = <Model>(response: Response): Promise<Model> => {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return model if it was returned in the response
            var contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                response.json().then(json => {
                    return resolve(json as Model);
                }).catch((reason) => {
                    // impossible to parse json
                    return reject(response.text());
                });
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(reason => reject(reason));
        }
    });
};