import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import {
	PaymentRequestButtonElement,
	useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequest, PaymentRequestOptions } from '@stripe/stripe-js';
import { useApi } from '@context/Api';

const useStyles = makeStyles({
	root: {},

	error: {
		fontSize: '0.8rem',
		color: 'red',
		marginTop: '1rem',
	},
	info: {
		fontSize: '0.8rem',
		color: '#b7b7b7',
		marginTop: '1rem',
	},
	label: {},
	progress: {
		width: '1rem',
		height: '1rem',
		marginRight: '0.5rem',
		color: 'white',
	},
});

const PayByPaymentRequest: FC<{ invoice: InvoiceToPay; done: () => void }> = (
	props
) => {
	const cls = useStyles({});
	const { invoice, done } = props;
	const { fetch } = useApi();
	const stripe = useStripe();

	const [error, setError] = useState<string>();
	const [info, setInfo] = useState<string>();

	const [paymentRequestRes, setPaymentRequestRes] = useState<PaymentRequest>();

	const paymentRequestOptions: PaymentRequestOptions = {
		country: invoice.CountryIso,
		currency: invoice.Currency.toLocaleLowerCase(),
		total: {
			label: 'total',
			amount: (invoice.Amount - invoice.PaidAmount) * 100,
		},
		requestPayerName: true,
		requestPayerEmail: true,
	};

	useEffect(() => {
		if (stripe) {
			const handleSuccess = async (intentId: string) => {
				const res = await fetch<{ error?: string }>('billing/stripe/fullfill', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						invoiceId: invoice.Id,
						paymentIntentId: intentId,
						// amount: invoice.Amount - invoice.PaidAmount,
					}),
				});

				if (res && res.error) {
					setError(res.error);
					return;
				}
				setError(undefined);
				done();
			};

			const effect = async () => {
				const { paymentIntentId, clientSecret, erro } = await fetch<{
					paymentIntentId?: string;
					clientSecret?: string;
					amount?: number;
					erro?: string;
				}>('billing/stripe/paymentintent', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						InvoiceId: invoice.Id,
					}),
				});

				if (erro) {
					setError(`error requesting payment intent ${erro}`);
					return;
				}

				const paymentRequest = stripe.paymentRequest(paymentRequestOptions);
				// Check the availability of the Payment Request API.
				const canMakePayment = await paymentRequest.canMakePayment();

				if (!canMakePayment) {
					// tslint:disable-next-line: max-line-length
					const explanation = `Apple Pay, Google Pay and Microsoft Pay need the Safari, Chrome or Ms Edge with payments configured to pay instantly. For apple play make sure you have a card in your wallet and a Touch ID capable device, like iPhone or macbook pro.`;
					setInfo(explanation);
					return;
				}

				setPaymentRequestRes(paymentRequest);
				paymentRequest.on('paymentmethod', async (ev) => {
					// Confirm the PaymentIntent without handling potential next actions (yet).
					const {
						paymentIntent,
						error: confirmError,
					} = await stripe.confirmCardPayment(
						clientSecret!,
						{ payment_method: ev.paymentMethod.id },
						{ handleActions: false }
					);

					if (confirmError) {
						// Report to the browser that the payment failed, prompting it to
						// re-show the payment interface, or show an error message and close
						// the payment interface.
						ev.complete('fail');
						setError(`The payment failed (${confirmError.message || ''})`);
						return;
					}

					if (paymentIntent) {
						// Report to the browser that the confirmation was successful, prompting
						// it to close the browser payment method collection interface.
						ev.complete('success');
						// Check if the PaymentIntent requires any actions and if so let Stripe.js
						// handle the flow. If using an API version older than "2019-02-11" instead
						// instead check for: `paymentIntent.status === "requires_source_action"`.
						if (paymentIntent.status === 'requires_action') {
							// Let Stripe.js handle the rest of the payment flow.
							const {
								error: confirmCardError,
							} = await stripe.confirmCardPayment(clientSecret!);
							if (confirmCardError) {
								setError(
									`The payment failed, please try a new payment method (${
										confirmCardError.message || ''
									})`
								);
								return;
							} else {
								await handleSuccess(paymentIntentId!);
								return;
							}
						} else {
							await handleSuccess(paymentIntentId!);
							return;
						}
					}
				});
			};
			effect();
		}
	}, [stripe]);

	return (
		<>
			{paymentRequestRes && (
				<PaymentRequestButtonElement
					options={{ paymentRequest: paymentRequestRes }}
				/>
			)}
			{info &&
				info.split('\n').map((l, i) => (
					<Typography
						color='textSecondary'
						key={i}
						className={classNames(cls.info)}
					>
						{l}
					</Typography>
				))}
			{error &&
				error.split('\n').map((l, i) => (
					<Typography color='error' key={i} className={classNames(cls.error)}>
						{l}
					</Typography>
				))}
		</>
	);
};
export default PayByPaymentRequest;
