import React, { FC, useState, FormEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { Button, Theme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
	StripeCardElementOptions,
	PaymentMethod,
	StripeError,
} from '@stripe/stripe-js';
import { useApi } from '@context/Api';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'& .StripeElement': {
				flex: '1 1 auto',
				boxSizing: 'border-box',
				height: '40px',
				padding: '10px 12px',
				border: '1px solid transparent',
				borderRadius: '4px',
				backgroundColor: 'white',
				width: '100%',
				marginBottom: '1rem',
				boxShadow: '0 1px 3px 0 #e6ebf1',

				transition: 'box-shadow 150ms ease',
				'&--focus': {
					boxShadow: '0 1px 3px 0 #cfd7df',
				},
				'&--invalid': {
					borderColor: '#fa755a',
				},
				'&--webkit-autofill': {
					backgroundColor: '#fefde5 !important',
				},
			},
		},
		fields: {},
		error: {
			fontSize: '0.8rem',
			color: 'red',
			marginTop: '1rem',
		},
		label: {},
		button: {
			flex: '1 1 auto',

			display: 'flex',
			justifyContent: 'center',
			background: theme.palette.primary.main,
			color: 'white',

			'&:hover': {
				background: theme.palette.primary.dark,
			},
		},
		buttonProcessing: {
			pointerEvents: 'none',
			boxShadow:
				'0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)',
			background: theme.palette.success.main,
			color: 'white',

			'&:hover': {
				background: theme.palette.success.dark,
			},
		},
		progress: {
			width: '1rem',
			height: '1rem',
			marginRight: '0.5rem',
			color: 'white',
		},
	})
);

const cardStyle: StripeCardElementOptions = {
	iconStyle: 'solid',
	hidePostalCode: true,
	style: {
		base: {
			fontFamily: 'circular',
			color: '#32325d',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

const PayByCard: FC<{ invoice: InvoiceToPay; done: () => void }> = ({
	invoice,
	done,
}) => {
	const cls = useStyles();
	const { fetch } = useApi();
	const [error, setError] = useState<string>();
	const [processing, setProcessing] = useState(false);
	const stripe = useStripe();
	const elements = useElements();

	// tslint:disable-next-line:no-any
	const handleServerResponse = async (response: any) => {
		if (!stripe) {
			return;
		}

		if (response.error) {
			setError(response.error ?? 'An unknown error occured');
			setProcessing(false);
			return;
		} else if (response.requires_action) {
			// Use Stripe.js to handle the required card action
			const {
				error: errorAction,
				paymentIntent,
			} = await stripe.handleCardAction(response.payment_intent_client_secret);

			if (errorAction || !paymentIntent) {
				setError(errorAction!.message ?? 'An unknown error occured');
				setProcessing(false);
				return;
			} else {
				// The card action has been handled
				// The PaymentIntent can be confirmed again on the server
				const serverResponse = await fetch('billing/stripe/cardpayment', {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						PaymentIntentId: paymentIntent.id,
						InvoiceId: invoice.Id,
						Currency: invoice.Currency.toLocaleLowerCase(),
					}),
				});

				handleServerResponse(serverResponse);
			}
		} else {
			setProcessing(false);
			done();
		}
	};

	const stripePaymentMethodHandler = async (paymentMethodResult: {
		paymentMethod?: PaymentMethod | undefined;
		error?: StripeError | undefined;
	}) => {
		if (paymentMethodResult.error || !paymentMethodResult.paymentMethod) {
			setError(
				paymentMethodResult.error!.message ?? 'An unknown error occured'
			);
			setProcessing(false);
			return;
		} else {
			const paymentResponse = await fetch('billing/stripe/cardpayment', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					InvoiceId: invoice.Id,
					PaymentMethodId: paymentMethodResult.paymentMethod.id,
				}),
			});

			handleServerResponse(paymentResponse);
		}
	};

	const onSubmitForm = async (ev: FormEvent) => {
		ev.preventDefault();
		setProcessing(true);

		if (!stripe || !invoice) {
			return;
		}

		const cardElement = elements!.getElement(CardElement);
		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement!,
		});

		stripePaymentMethodHandler(result);
	};

	return (
		<>
			<form onSubmit={onSubmitForm} className={cls.root}>
				<div className={cls.fields}>
					<CardElement options={cardStyle} />
					<Button
						className={classNames(
							cls.button,
							processing && cls.buttonProcessing
						)}
						type='submit'
						color='primary'
						disabled={!stripe}
					>
						{processing ? (
							<>
								<CircularProgress size='1rem' className={cls.progress} />{' '}
								Processing...
							</>
						) : (
							'Submit Payment'
						)}
					</Button>
				</div>
			</form>
			{error &&
				error.split('\n').map((l, i) => (
					<div key={i} className={cls.error}>
						{l}
					</div>
				))}
		</>
	);
};
export default PayByCard;
