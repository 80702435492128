import * as React from 'react';

import { ApiContext } from '../../@context/Api';

import { Button, makeStyles, createStyles, Theme } from '@material-ui/core';
import { EntryContext } from '../../@context/Entry';
import Loading from '../Loading';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            bottom: 13,
            right: 30,
        },
        saveButton: {
            boxShadow: 'none'
        }
    }),
);

export type DefaultCreatorProps = {
    type: string;
};

type Props = {
    type: string;
};

const DefaultCreator: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { create } = React.useContext(ApiContext);
    const { open } = React.useContext(EntryContext);
    const [loading, setloading] = React.useState(false);

    const _empty = async () => {
        setloading(true);
        const added = await create(props.type);
        await open(`${added.Id}:${added.Type}`, added.Id, added.Type).then(() => setloading(false));
    };

    return (
        <div className={classes.container}>
            {!!loading && <Loading />}
            <Button
                className={classes.saveButton}
                variant='contained'
                color='primary'
                size='small'
                onClick={_empty}
            >
                Add
            </Button>
        </div>
    );
};

export default DefaultCreator;