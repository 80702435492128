import * as React from 'react';
import { ApiContext } from '../../@context/Api';
import {
    IconButton, ListItem, ListItemSecondaryAction, makeStyles, createStyles
} from '@material-ui/core';
import { EntryContext } from '../../@context/Entry';
import { Close } from '@material-ui/icons';
import { listItemIconMinWidth } from '../../constants/stylesheet';
import PreviewRenderer from '../PreviewRenderer';

type EntryFieldItemProps = {
    field: FieldDto;
    path: string;
    value: EntryDto;
    remove?: () => void;
};

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            paddingLeft: 0
        },
        icon: {
            minWidth: listItemIconMinWidth
        },
        primaryText: {
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'block'
        },
        secondaryText: {
            fontSize: '.7rem',
            color: 'black',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        secondaryAction: {
            right: 4
        }
    }),
);

type Props = EntryFieldItemProps;

const EntryFieldItem: React.FunctionComponent<Props> = ({ value, field, remove, path }) => {

    const classes = useStyles();
    const { lastChange } = React.useContext(ApiContext);
    const { open } = React.useContext(EntryContext);

    const [current, setCurrent] = React.useState<EntryDto>(value);

    React.useEffect(
        () => {
            if (value.Id !== current.Id) {
                setCurrent(value);
            }
            if (!lastChange) {
                return;
            }
            const changed = lastChange.Modified.find(m => m.Id === value.Id);
            if (changed) {
                setCurrent(changed as EntryDto);
                return;
            }
            const deleted = lastChange.Deleted.find(m => m.Id === value.Id);
            if (deleted && remove) {
                remove();
            }

        },
        [lastChange, value]
    );
    if (!current) {
        return null;
    }

    return (
        <ListItem
            onClick={() => open(`${path}/${current.Id}`, current.Id, current.Type)}
            classes={{ root: classes.listItem }}
            component='div'
            ContainerComponent='div'
        >
            <PreviewRenderer entry={current} />
            {remove && (
                <ListItemSecondaryAction
                    classes={{ root: classes.secondaryAction }}
                >
                    <IconButton
                        edge='end'
                        aria-label='Remove'
                        onClick={(e) => { e.stopPropagation(); remove(); }}
                        size='small'
                    >
                        <Close />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};

export default EntryFieldItem;