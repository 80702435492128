import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(4, 4, 0, 4)
        },
        bold: {
            fontWeight: 500
        },
    }));

const PayByTransfer: FC<{ invoice: InvoiceToPay, done: () => void }> =
    ({ invoice, children }) => {
        const classes = useStyles({});
        return (
            <div className={classes!.container}>
                <Typography>
                    <ReactMarkdown source={invoice.TransferInfos} />
                </Typography>
                {children}
            </div>
        );
    };

    // <br />
    //             <Button
    //                 target="_blank"
    //                 href="/assets/paymentSlip.pdf"
    //                 variant="outlined"
    //             >
    //                 Payment slip
    //             </Button>

export default PayByTransfer;