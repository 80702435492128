import * as React from 'react';
import { MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
type Props = {
    field: FieldDto;
    value: string;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#858585',
            fontWeight: 600
        },
        labelUnsaved: {
            color: '#3f51b5',
        },
    }));

const PaymentMethodField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const {
        value, field, setChanges, changes
    } = props;

    return (
        <TextField
            autoComplete="off"
            select={true}
            label={field.Legend}
            fullWidth={true}
            value={value}
            onChange={(e) => setChanges({ ...changes, [field.PropertyName]: e.target.value })}
            InputLabelProps={{
                classes: { root: changes[field.PropertyName] && classes!.labelUnsaved || classes!.label }
            }}
        >
            {
                // TODO: cool to have a iteration to generate automatically
            }
            
            <MenuItem key="Stripe" value="Stripe">
                Stripe
            </MenuItem>
            <MenuItem key="Paypal" value="Paypal">
                Paypal
            </MenuItem>
            <MenuItem key="Transfer" value="Transfer">
                Transfer
            </MenuItem>
            <MenuItem key="Cash" value="Cash">
                Cash
            </MenuItem>
            <MenuItem key="Other" value="Other">
                Other
            </MenuItem>
        </TextField>
    );
};

export default PaymentMethodField;