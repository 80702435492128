import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography, List, ListItem, ListItemText,
    DialogTitle, Dialog, ListItemAvatar, Avatar
} from '@material-ui/core';
import { useApi } from '../../@context/Api';
import EntryIcon from '../EntryIcon';
import { listItemIconMinWidth } from '../../constants/stylesheet';
import EntryFieldSelect from './EntryFieldSelect';
import EntryFieldItem from './EntryFieldItem';
import { useEffect } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
        input: {
            flex: 1,
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            flexBasis: 200,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
        header: {},
        headerTitle: {
            fontWeight: 600
        },
        headerAddButton: {},
        list: {},
        listItem: {},
        noHover: {
            '&:hover': {
                background: 'none'
            }
        },
        listItemIcon: {
            minWidth: listItemIconMinWidth
        },
        primaryText: {
            fontSize: '1.2rem'
        },
        secondaryText: {
            fontSize: '.7rem',
            color: 'black'
        },
    }),
);

type ListEntryFieldProps = {
    field: FieldDto;
    type: string;
    id: string;
    path: string;
    value: EntryDto[];
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
    refresh: () => void;
};

type Props = ListEntryFieldProps;

const ListEntryField: React.FunctionComponent<Props> = (props) => {

    const classes = useStyles();
    const { create, createShipment, getAll, save, lastChange } = useApi();

    const {
        value, field, changes, setChanges, refresh, path,
        id, type
    } = props;

    const [modalOpen, setModalOpen] = React.useState(false);
    const [createQuery, setCreateQuery] = React.useState<string>();

    const _select = async (e?: EntryDto) => {
        if (!e) { return; }

        await save(e.Type, e.Id, { [field.ForeignKey]: id });
        refresh();
    };

    const handleClose = () => {
        setModalOpen(false);
        setCreateQuery(undefined);
    };

    const _create = async (query: string | undefined, concreteType: string) => {
        let newEntry: EntryDto;

        if (concreteType === 'EmailShipment') {
            newEntry = await createShipment(concreteType, { Id: id, Type: type }, query || undefined);
        } else {
            newEntry = await create(concreteType, { Id: id, Type: type }, query || undefined);
        }

        var changed = await save(newEntry.Type, newEntry.Id, { [field.ForeignKey]: id });
        const rest = { ...changes };
        setChanges(rest);
        handleClose();
        refresh();
        return changed;
    };

    const remove = (entry: EntryDto) => async () => {
        await save(entry.Type, entry.Id, {
            [field.ForeignKey]: undefined
        });
        refresh();

    };

    const handleOpen = async (query?: string) => {
        setCreateQuery(query);
        if (field.ConcreteTypes.length > 1) {
            setModalOpen(true);
        } else {
            await _create(query, field.ConcreteTypes[0]);
        }
    };

    const _list = (query: string) =>
        getAll(field.PropertyType, query)
            .then(entries => entries.filter(e => !value.some(v => v.Id === e.Id)));

    useEffect(
        () => {
            if (!lastChange) {
                return;
            }
            // changed are handled in the item itself
            // const changed = lastChange.Modified.filter(m => value.some(v => m.Id === v.Id));
            // if (changed) {
            //     setCurrent(changed as EntryDto);
            //     return;
            // }
            const deleted = lastChange.Deleted.filter(m => value.some(v => m.Id === v.Id));
            if (deleted && deleted.length) {
                refresh();
            }
        },
        [lastChange, value, refresh]
    );

    return (
        <>
            <div className={classes.header}>
                <Typography className={classes.headerTitle}>
                    {field.Legend}
                </Typography>
            </div>
            <List
                className={classes.list}
            >
                {value.map((v) => (
                    <EntryFieldItem
                        key={v.Id}
                        path={path}
                        field={field}
                        value={v}
                        remove={field.DeleteButton ? remove(v) : undefined}
                    />
                ))}
                <EntryFieldSelect
                    key={value.length}
                    path={path}
                    field={field}
                    onCreate={handleOpen}
                    change={_select}
                    list={_list}
                />
            </List>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
            >
                <DialogTitle id='simple-dialog-title'>Select {field.PropertyType}</DialogTitle>
                <List>
                    {field.ConcreteTypes.map(concreteType => (
                        <ListItem button={true} onClick={() => _create(createQuery, concreteType)} key={concreteType}>
                            <ListItemAvatar>
                                <Avatar>
                                    <EntryIcon entry={{ Type: concreteType }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={concreteType} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
    // <Paper className={classes.root} elevation={0}>
    //     {value && <div className={classes.iconButton}>
    //         <EntryIcon entry={value} />
    //     </div>}
    //     {value &&
    //         <TextField
    //             className={classes.input}
    //             value={`${value.PreviewTitle}`}
    //             onClick={() => open(`${path}/${value.Id}`, value.Id, value.Type)}
    //             InputProps={{
    //                 readOnly: true,
    //                 endAdornment: (
    //                     <InputAdornment position="end">
    //                         <IconButton className={classes.iconButton} aria-label="Remove" onClick={remove}>
    //                             <Cancel />
    //                         </IconButton>
    //                     </InputAdornment>)
    //             }}
    //         />
    //     }
    //     {!value &&
    //         <div className={classes.input}>
    //             <SelectEntry
    //                 create={_create}
    //                 change={_change}
    //                 list={_list}
    //                 label={field.Legend}
    //             />
    //         </div>
    //     }
    // </Paper>
    // );
};

export default ListEntryField;