import * as React from 'react';
import withStyles, { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import Portal from './Portal';

const rotate = {
    from: {
        transformOrigin: 'center',
        transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 0)`
    },
    to: {
        transformOrigin: 'center',
        transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 360deg)`,
    }
};

const styles = {
    '@global': {
        'html.no-cursor': {
            cursor: 'none'
        }
    },
    loadingContainer: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 99,
        display: 'none',
        '&:first-child': {
            display: 'block',
        }
    },
    movingContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '2rem',
        height: '2rem',
    },
    cursorLoading: {
        transition: 'transform linear 0.2s',
        borderRadius: '50%',
        border: '1px solid black',
        borderTopColor: 'transparent',
        background: 'transparent',
        width: '2rem',
        height: '2rem',
        display: 'block',
        transform: 'translate3d(-50%,-50%,0)',
        animationName: '$rotate',
        animationIterationCount: 'infinite',
        animationDuration: '1s',
        animationTimingFunction: 'linear',
    },
    '@keyframes rotate': rotate
};

export type LoadingProps = {};
type Props = LoadingProps &
    WithStyles<keyof typeof styles>;

class Loading extends React.Component<Props> {
    private cursor: React.RefObject<HTMLDivElement> = React.createRef();
    constructor(props: Props) {
        super(props);

        this._follow = this._follow.bind(this);
    }
    componentDidMount() {
        window.addEventListener('mousemove', this._follow);
        const html = document.querySelector('html')!;
        html.classList.add('no-cursor');
    }
    componentWillUnmount() {
        window.removeEventListener('mousemove', this._follow);
        const html = document.querySelector('html')!;
        html.classList.remove('no-cursor');
    }
    render() {
        const { classes } = this.props;

        return (
            <Portal>
                <div className={classes!.loadingContainer}>
                    <div className={classes!.movingContainer} ref={this.cursor}>
                        <span className={classes!.cursorLoading} />
                    </div>
                </div>
            </Portal>
        );
    }

    private _follow(ev: MouseEvent) {
        const { current } = this.cursor;
        if (!current) { return; }
        current.style.transform = `translate3d(${ev.clientX}px,${ev.clientY}px,0)`;
    }
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(Loading);