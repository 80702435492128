import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Theme, createStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

type Props = {
    field: FieldDto;
    value: string;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        underline: {
            '&:before': {
                borderBottomColor: '#3f51b5'
            }
        },
        label: {
            color: '#858585'
        },
        labelNovalue: {
            color: '#cccccc'
        },
        labelUnsaved: {
            color: '#3f51b5',
            fontWeight: 600
        },
    }));

const TextInputField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const {
        value, field, setChanges, changes
    } = props;

    const isMultiline = field.Multiline;

    return (
        <TextField
            autoComplete='off'
            fullWidth={true}
            multiline={isMultiline}
            value={value || ''}
            label={field.Legend}
            onChange={(e) => setChanges({ ...changes, [field.PropertyName]: e.target.value })}
            InputProps={{
                classes: { underline: changes[field.PropertyName] && classes!.underline || undefined }
            }}
            InputLabelProps={{
                classes: {
                    root: classNames(
                        classes!.label,
                        changes[field.PropertyName] && classes!.labelUnsaved,
                        !changes[field.PropertyName] && classes!.labelNovalue,
                    )
                }
            }}
        />
    );
};

export default TextInputField;