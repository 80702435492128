import * as React from 'react';
import { MenuItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
type Props = {
	field: FieldDto;
	value: string;
	changes: Partial<EntryDto>;
	setChanges: (changes: Partial<EntryDto>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			color: '#858585',
			fontWeight: 600,
		},
		labelUnsaved: {
			color: '#3f51b5',
		},
	})
);

const InvoiceLanguageField: React.FunctionComponent<Props> = (props) => {
	const classes = useStyles();

	const { value, field, setChanges, changes } = props;

	return (
		<TextField
			autoComplete='off'
			select={true}
			label={field.Legend}
			fullWidth={true}
			value={value}
			onChange={(e) =>
				setChanges({ ...changes, [field.PropertyName]: e.target.value })
			}
			InputLabelProps={{
				classes: {
					root:
						(changes[field.PropertyName] && classes!.labelUnsaved) ||
						classes!.label,
				},
			}}
		>
			<MenuItem key='en' value='en'>
				English
			</MenuItem>
			<MenuItem key='pt' value='pt'>
				Portuguese
			</MenuItem>
			<MenuItem key='fr' value='fr'>
				French
			</MenuItem>
			<MenuItem key='de' value='de'>
				German
			</MenuItem>
		</TextField>
	);
};

export default InvoiceLanguageField;
