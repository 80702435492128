import * as React from 'react';
import { withEntry, InjectedEntryProps } from '../../@context/Entry';
import { Button } from '@material-ui/core';
import EntryField from './EntryField';
import { InjectedApiProps, withApi } from '@context/Api';

type BillingClientFieldProps = {
    field: FieldDto;
    type: string;
    id: string;
    path: string;
    value: EntryDto;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => Promise<void>;
    refresh: () => void;
};

type Props = BillingClientFieldProps & InjectedApiProps & InjectedEntryProps;

const BillingClientField: React.FunctionComponent<Props> = (props) => {
    // const classes = useStyles();
    const {
        value, field, changes, setChanges, refresh, path,
        id, type,
        apiCtx: { get }
    } = props;

    const copy = async (clientId: string) => {
        const {
            FirstName,
            LastName,
            Address,
            OrganizationName,
            VATNumber,
            PostalCode,
            City,
            State,
            CountryIso
        } = await get('Client', clientId) as Client;

        const chgs = {
            ...changes,
            FirstName,
            LastName,
            Address,
            OrganizationName,
            VATNumber,
            PostalCode,
            City,
            CountryState: State,
            CountryIso
        } as Partial<BillingDocument>;

        Object.keys(chgs).filter(k => chgs[k] == null).forEach(k => {
            delete chgs[k];
        });

        await setChanges(chgs);

        refresh();
    };

    return (
        <>
            <EntryField
                setChanges={setChanges}
                field={field}
                id={id}
                changes={changes}
                type={type}
                path={path}
                refresh={refresh}
                value={value}
            />
            {value && value.Id && <Button onClick={() => copy(value.Id)}>Copy Values</Button>}
        </>
    );
};

export default withEntry(withApi(BillingClientField));