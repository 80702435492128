import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
type Props = {
    field: FieldDto;
    value: string;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#858585',
            fontWeight: 600
        },
        labelUnsaved: {
            color: '#3f51b5',
        },
    }));

const DomainNameField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const {
        value, field, setChanges, changes
    } = props;

    return (
        <TextField
            autoComplete="off"
            fullWidth={true}
            value={value}
            label={field.Legend}
            onChange={(e) => setChanges({...changes, [field.PropertyName]: e.target.value })}
            InputLabelProps={{
                classes: { root: changes[field.PropertyName] && classes!.labelUnsaved || classes!.label }
            }}
        />
    );
};

export default DomainNameField;