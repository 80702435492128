import * as React from 'react';
import { Subtract } from 'utility-types';
import { withRouter, RouteComponentProps } from 'react-router';
import { InjectedApiProps, withApi } from './Api';
export type TDetail = {
    path: string;
    entry: EntryDto;
};

type Entry = {
    details: TDetail[];
    open: (path: string, id: string, type: string) => Promise<void>;
    close: (path: string) => void;
};

const initValue: Entry = {
    details: [],
    open: 0 as never,
    close: 0 as never
};

export const EntryContext = React.createContext<Entry>(initValue);

type EntryProviderProps = {
    baseURL: string;
} &
    InjectedApiProps &
    RouteComponentProps<{ type: string; ids: string }>;

class EntryProviderRouter extends React.Component<EntryProviderProps, Entry> {
    public static Consumer = EntryContext.Consumer;
    public constructor(props: EntryProviderProps) {
        super(props);

        this.state = {
            details: [],
            open: this._open.bind(this),
            close: this._close.bind(this),
        };
    }

    async componentDidMount() {
        const { location: { pathname }, baseURL } = this.props;
        const path = pathname.replace(baseURL, '');
        if (!path) {
            return;
        }
        // tslint:disable-next-line: no-console
        var detail = path
            .split('/').pop();
        if (!detail) {
            return;
        }
        const { apiCtx: { get } } = this.props;
        const [type, id] = detail.split(':');
        if (!type || !id) {
            return;
        }
        const entry = await get(type, id);

        this.setState(() => ({ details: [{ path, entry }] }));
    }

    async componentDidUpdate(oldProps: EntryProviderProps, oldState: Entry) {
        const { location: newLocation, baseURL, location: { pathname } } = this.props;
        const { location: oldocation } = oldProps;

        if (oldocation !== newLocation) {
            const path = pathname.replace(baseURL, '');
            if (!path) {
                this.setState(
                    () => ({ details: [] }), () => this._updateUrl
                );
            }
        }
    }

    render() {
        return (
            <EntryContext.Provider value={this.state}>
                {this.props.children}
            </EntryContext.Provider>
        );
    }

    private async _open(path: string, id: string, type: string) {
        const { apiCtx: { get } } = this.props;
        const entry = await get(type, id);
        this.setState(
            ({ details }) => ({ details: [...details.filter(d => d.path !== path), { path, entry }] }),
            this._updateUrl);
    }

    private _close(path: string) {
        this.setState(
            ({ details }) => ({ details: details.filter(d => d.path !== path) }),
            this._updateUrl);
    }
    private _updateUrl() {
        const { baseURL, history: { push } } = this.props;
        const last = [...this.state.details].pop();
        push(`${baseURL}${(last && `/${last.entry.Type}:${last.entry.Id}`) || ''}`);
    }
}
export const EntryProvider = withApi(withRouter(EntryProviderRouter));

export type InjectedEntryProps = { entryCtx: Entry };
export const withEntry =
    <OriginalProps extends InjectedEntryProps>(
        Component: React.ComponentType<OriginalProps>
    ): React.FunctionComponent<Subtract<OriginalProps, InjectedEntryProps>> => {

        return (props: Subtract<OriginalProps, InjectedEntryProps>) => {
            return (
                <EntryContext.Consumer>
                    {(entryCtx) => <Component {...{ ...(props as object), entryCtx } as OriginalProps} />}
                </EntryContext.Consumer>
            );
        };
    };