import { CSSProperties } from '@material-ui/styles';

export const fontSize = 16;
export const listItemIconMinWidth = 40;
export const detailWidth = 650;

export const underline: CSSProperties = {
    content: '""',
    position: 'absolute',
    top: '100%',
    marginTop: '0.05em',
    left: 0,
    width: '100%',
    height: 2,
    backgroundColor: 'black'
};