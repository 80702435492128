import * as React from 'react';
import {
	Grid,
	makeStyles,
	Theme,
	createStyles,
	TextField,
	Paper,
	Accordion as ExpansionPanel,
	AccordionSummary as ExpansionPanelSummary,
	AccordionDetails as ExpansionPanelDetails,
	Button,
	Typography,
	Menu,
	MenuItem,
} from '@material-ui/core';
import {
	ExpandMore,
	Add,
	PictureAsPdf,
	Mail,
	Done,
	FileCopy,
	Payment,
} from '@material-ui/icons';
import { useApi } from '../../@context/Api';
import { throttle } from 'lodash';
import toPrettyCurrency from '../../@utils/toPrettyCurrency';
import { API_URL } from '../../App';
import { useState, FC, useEffect } from 'react';
import Loading from 'components/Loading';
import { EntryContext } from '@context/Entry';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		listItem: {
			paddingLeft: 0,
			flexWrap: 'wrap',
		},
		utilitiesTitleContainer: {
			// display: 'flex'
		},
		utilityItitle: {
			display: 'flex',
			paddingRight: theme.spacing(2),
		},
		legend: {
			// color: '#858585',
			paddingRight: theme.spacing(1),
		},
		value: {
			fontWeight: 600,
		},
		field: {
			marginBottom: theme.spacing(1),
			'&:last-child': {
				marginBottom: 0,
			},
		},
		iframeContainer: {
			width: '100%',
			height: '60vh',
		},
		iframe: {
			width: '100%',
			height: '100%',
			border: 0,
		},
		btnAddContainer: {
			width: '100%',
			padding: theme.spacing(0, 1, 0, 1),
			display: 'flex',
		},
		btnLegend: {
			marginLeft: 10,
		},
		'@media screen and (max-width: 1430px)': {
			btnLegend: {
				marginLeft: 0,
				display: 'none',
			},
		},
		btnAdd: {
			marginRight: 'auto',
			boxShadow: 'none',
		},
		invoiceButton: {
			marginLeft: 10,
			boxShadow: 'none',
			// '&:hover': {
			//     color: 'white'
			// }
		},
		templateRemoveItemBtn: {
			marginRight: 10,
			boxShadow: 'none',
			backgroundColor: 'rgb(255, 100, 100)',
			color: 'white',

			'&:hover': {
				backgroundColor: 'rgb(255, 0, 0)',
			},
		},
		templateSaveItemBtn: {
			boxShadow: 'none',
			backgroundColor: 'rgb(100, 200, 100)',
			color: 'white',

			'&:hover': {
				backgroundColor: 'rgb(0, 150, 0)',
			},
		},
	})
);
type Props = {
	path: string;
	entry: Invoice;
	changes: Partial<Invoice>;
	refresh: () => void;
};

const BillingItemEditor: FC<{
	item: BillingItem;
	refresh: () => Promise<void>;
}> = ({ item, refresh }) => {
	const classes = useStyles();
	const [changes, setChanges] = useState<Partial<BillingItem>>({});
	const preview = { ...item, ...changes };
	const { save, deleteEntry } = useApi();

	const onInputChange = (prop: keyof BillingItem) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => setChanges({ ...changes, [prop]: e.target.value });

	const onNumberInputChange = (prop: keyof BillingItem) => (
		e: React.ChangeEvent<HTMLInputElement>
	) => setChanges({ ...changes, [prop]: e.target.valueAsNumber });

	const _save = async () => {
		await save('BillingItem', item.Id, changes);
		await refresh();
		setChanges({});
	};

	const _delete = async () => {
		await deleteEntry(item.Type, item.Id);
		await refresh();
	};

	return (
		<Grid container={true} spacing={3}>
			<Grid item={true} xs={6}>
				<TextField
					classes={{ root: classes.field }}
					label='Title'
					fullWidth={true}
					value={preview.Title}
					onChange={onInputChange('Title')}
				/>
			</Grid>
			<Grid item={true} xs={6}>
				<TextField
					classes={{ root: classes.field }}
					label='Amount'
					type='number'
					value={preview.Amount}
					fullWidth={true}
					onChange={onNumberInputChange('Amount')}
				/>
			</Grid>
			<Grid item={true} xs={12}>
				<TextField
					classes={{ root: classes.field }}
					label='Description'
					value={preview.Description}
					onChange={onInputChange('Description')}
					multiline={true}
					fullWidth={true}
				/>
			</Grid>

			<Grid item={true} xs={12}>
				<Button
					size='small'
					variant='contained'
					onClick={_delete}
					classes={{ root: classes.templateRemoveItemBtn }}
				>
					remove
				</Button>
				{Object.keys(changes).length > 0 ? (
					<Button
						size='small'
						variant='contained'
						onClick={_save}
						classes={{ root: classes.templateSaveItemBtn }}
					>
						save
					</Button>
				) : null}
			</Grid>
		</Grid>
	);
};

const InvoiceUtilities: FC<Props> = ({
	entry,
	changes,
	refresh,
	children,
	path,
}) => {
	const classes = useStyles();
	const { Type, Id, Currency } = entry;
	const { fetch, create, createShipment, getAll } = useApi();
	const [markingAsPaid, setMarkingAsPaid] = useState(false);
	const [BillingItems, setBillingItems] = useState<BillingItem[]>([]);
	const [itemTemplates, setItemTemplates] = useState<BillingItemTemplate[]>([]);
	const [expandedIndex, setExpandedIndex] = useState<number>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const { open } = React.useContext(EntryContext);
	const previewWindow = React.useRef<HTMLIFrameElement>(null);

	const refreshItems = async () => {
		const options: RequestInit = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		};

		const items = await fetch<BillingItem[]>(
			`billing/${Type}/${Id}/items`,
			options
		);

		setBillingItems(items);
	};

	const refreshItemTemplates = async () => {
		getAll('BillingItemTemplate').then((items) =>
			setItemTemplates(items as BillingItemTemplate[])
		);
	};

	const updatePreview = (
		chgs: Partial<Invoice>,
		billingItems: BillingItem[]
	) => {
		if (previewWindow && previewWindow.current) {
			const patchDocument = Object.keys(chgs).map((k) => ({
				op: 'replace',
				path: `/${k}`,
				value: chgs[k],
			}));

			const options: RequestInit = {
				method: 'PATCH',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ changes: patchDocument, billingItems }),
			};
			fetch<string>(`billing/${Type}/${Id}/preview`, options).then(
				(preview) =>
					(previewWindow!.current!.contentDocument!.body.innerHTML = preview)
			);
		}
	};

	const throttled = React.useRef(throttle(updatePreview, 1000));

	useEffect(() => {
		if (previewWindow) {
			throttled.current(changes, BillingItems);
		}
	}, [BillingItems, previewWindow, changes]);

	useEffect(() => {
		refreshItems();
		refreshItemTemplates();
	}, []);

	const markAsPaid = () => {
		setMarkingAsPaid(true);
		fetch(`billing/${Type}/${Id}/paid`, {}).then(() => {
			setMarkingAsPaid(false);
			refresh();
		});
	};

	const openAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeAdd = () => {
		setAnchorEl(null);
	};

	const add = async (chg?: Partial<BillingItem>) => {
		closeAdd();
		await create('BillingItem', entry, undefined, chg);
		await refreshItems();
		setExpandedIndex(BillingItems.length);
	};

	const createInvoiceEmail = async () => {
		const result = await createShipment('EmailShipment', entry);
		await open(`${path}/${result.Id}`, result.Id, result.Type);
		refresh();
	};

	const duplicate = async () => {
		const result = await create(Type, entry, undefined, {});
		await open(`${path}/${result.Id}`, result.Id, result.Type);
	};

	const AddTemplate = (template: BillingItemTemplate) => (
		event: React.MouseEvent<HTMLLIElement>
	) => {
		add({
			Title: template.Title,
			Description: template.Description,
			Amount: template.Amount,
		});
	};
	return (
		<Grid item={true} xs={12}>
			<Grid container={true} spacing={3}>
				<Grid item={true} xs={12}>
					<Grid container={true} spacing={2}>
						<Grid item={true} xs={12}>
							<Paper classes={{ root: classes.iframeContainer }} elevation={2}>
								<iframe ref={previewWindow} className={classes.iframe} />
							</Paper>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={true} xs={12}>
					{BillingItems.map((item, i) => {
						const expanded = expandedIndex === i;
						const { Title, Amount } = item;
						return (
							<ExpansionPanel
								key={item.Id}
								expanded={expanded}
								onChange={() => setExpandedIndex(expanded ? undefined : i)}
							>
								<ExpansionPanelSummary expandIcon={<ExpandMore />}>
									<div className={classes.utilitiesTitleContainer}>
										<Typography className={classes.legend}>{Title}</Typography>
										{Amount !== 0 && (
											<Typography className={classes.value}>
												{toPrettyCurrency(Amount, Currency)}
											</Typography>
										)}
										{Amount === 0 && (
											<Typography className={classes.value}>
												{toPrettyCurrency(Amount, Currency)}
											</Typography>
										)}
									</div>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<BillingItemEditor refresh={refreshItems} item={item} />
								</ExpansionPanelDetails>
							</ExpansionPanel>
						);
					})}
				</Grid>
				<Grid item={true} xs={12}>
					<Grid container={true} spacing={3}>
						<div className={classes.btnAddContainer}>
							<Button
								className={classes.btnAdd}
								aria-label='Remove'
								onClick={openAdd}
								color='primary'
								variant='contained'
								size='small'
								// startIcon={<Add />}
							>
								<Add />
								<span className={classes.btnLegend}>Add</span>
							</Button>
							<Menu
								id='simple-menu'
								anchorEl={anchorEl}
								keepMounted={true}
								open={Boolean(anchorEl)}
								onClose={closeAdd}
							>
								<MenuItem onClick={() => add()}>Empty</MenuItem>
								{itemTemplates.map((i) => (
									<MenuItem key={i.Id} onClick={AddTemplate(i)}>
										{i.TemplateTitle}
									</MenuItem>
								))}
							</Menu>
							<Button
								className={classes!.invoiceButton}
								variant='contained'
								size='small'
								href={`${API_URL}/billing/${Type}/${Id}/pdf`}
								target='_blank'
								// startIcon={<PictureAsPdf />}
							>
								<PictureAsPdf />
								<span className={classes.btnLegend}>PDF</span>
							</Button>
							<Button
								className={classes!.invoiceButton}
								variant='contained'
								size='small'
								href={`/Pay?id=${Id}`}
								target='_blank'
								// startIcon={<Payment />}
							>
								<Payment />
								<span className={classes.btnLegend}>Pay-page</span>
							</Button>
							<Button
								className={classes!.invoiceButton}
								variant='contained'
								size='small'
								onClick={createInvoiceEmail}
								// startIcon={<Mail />}
							>
								<Mail />
								<span className={classes.btnLegend}>Email</span>
							</Button>
							<Button
								className={classes!.invoiceButton}
								variant='contained'
								size='small'
								color='primary'
								onClick={markAsPaid}
								// startIcon={<Done />}
							>
								<Done />
								<span className={classes.btnLegend}>
									{markingAsPaid && <Loading />}
									{markingAsPaid ? 'saving...' : 'Mark paid'}
								</span>
							</Button>
							<Button
								className={classes!.invoiceButton}
								variant='contained'
								size='small'
								color='primary'
								onClick={duplicate}
								// startIcon={<FileCopy />}
							>
								<FileCopy />
								<span className={classes.btnLegend}>duplicate</span>
							</Button>
						</div>
					</Grid>
				</Grid>
				{children}
			</Grid>
		</Grid>
	);
};

export default InvoiceUtilities;
