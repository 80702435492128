import * as React from 'react';
import {
    Grid,
    makeStyles,
    Theme,
    createStyles,
    TextField,
    Button
} from '@material-ui/core';
import { useApi } from '../../@context/Api';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            paddingLeft: 0,
            flexWrap: 'wrap'
        },
        utilitiesTitleContainer: {
            // display: 'flex'
        },
        utilityItitle: {
            display: 'flex',
            paddingRight: theme.spacing(2),
        },
        legend: {
            // color: '#858585',
            paddingRight: theme.spacing(1),
        },
        value: {
            fontWeight: 600
        },
        field: {
            marginBottom: theme.spacing(1),
            '&:last-child': {
                marginBottom: 0
            }
        },
        iframeContainer: {
            width: '100%',
            height: '60vh'
        },
        iframe: {
            width: '100%',
            height: '100%',
            border: 0
        },
        btnAddContainer: {
            width: '100%',
            padding: theme.spacing(0, 1, 0, 1)
        },
        btnAdd: {
            // float: 'right'
        },
        invoiceButton: {
            marginBottom: 10,
            boxShadow: 'none',
            '&:hover': {
                color: 'white'
            }
        }
    }));
type Props = {
    entry: Client;
};

const ClientUtilities: FC<Props> = ({ entry, children }) => {
    const classes = useStyles();
    const { Id } = entry;
    const { fetch } = useApi();
    const [password, setPassword] = React.useState('');

    const savePassword = async () => {
        const options: RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(password)
        };
        try {
            await fetch<void>(`account/${Id}/password`, options);
            alert('password changed');
            setPassword('');
        } catch (errorstr) {
            try {
                const error: string[] = JSON.parse(errorstr);
                alert(error.join(' '));
            } catch (error) {
                alert(errorstr);
            }

        }
    };

    return (
        <Grid
            item={true}
            xs={12}
        >
            <Grid
                container={true}
                spacing={3}
            >

                <Grid
                    item={true}
                    xs={12}
                >
                    <Grid
                        container={true}
                        spacing={3}
                    >
                        <Grid
                            item={true}
                            xs={6}
                        >
                            <TextField
                                classes={{ root: classes.field }}
                                label='Password'
                                fullWidth={true}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Grid>

                        <div className={classes.btnAddContainer}>
                            <Button
                                className={classes.btnAdd}
                                aria-label='Remove'
                                onClick={savePassword}
                                color='primary'
                                variant='contained'
                                size='small'
                            >
                                set password
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                {children}
            </Grid>
        </Grid>
    );
};

export default ClientUtilities;