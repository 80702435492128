import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { List } from '@material-ui/core';
import { ApiContext } from '../../@context/Api';
import EntryFieldItem from './EntryFieldItem';
import EntryFieldSelect from './EntryFieldSelect';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            alignItems: 'center',
        },
        input: {
            flex: 1,
        },
        margin: {
            margin: theme.spacing(1),
        },
        textField: {
            flexBasis: 200,
        },
        iconButton: {
            padding: '10px 10px 10px 0',
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
    }),
);

type Props = {
    field: FieldDto;
    type: string;
    id: string;
    path: string;
    value: EntryDto;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
    refresh: () => void;
};

const SelectField: React.FunctionComponent<Props> = (props) => {
    const { getAll } = React.useContext(ApiContext);

    const classes = useStyles();
    const {
        value, field, changes, setChanges, path
    } = props;

    const remove = () => {
       
        setChanges({
            ...changes,
            [field.NavigationProperty]: undefined,
            [field.PropertyName]: undefined
        });
    };

    const _change = (e?: EntryDto) => {
        setChanges({
            ...changes,
            [field.NavigationProperty]: e && e.Id,
            [field.PropertyName]: e
        });
    };

    const _list = (query: string) =>
        getAll(field.PropertyType, query);

    return (
        <List dense={true} className={classes.root}>
            {value && 
                <EntryFieldItem path={path} field={field} remove={remove} value={value} />}
            {!value && 
                <EntryFieldSelect 
                    preload={true} 
                    path={path} 
                    field={field}
                    change={_change} 
                    list={_list} 
                />}
        </List>
    );
};

export default SelectField;