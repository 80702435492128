import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Button, withStyles, Typography } from '@material-ui/core';
import { RadioButtonUnchecked, CheckCircle } from '@material-ui/icons';
import { InjectedApiProps, withApi } from '../@context/Api';
import { StyleRules, WithStyles } from '@material-ui/core/styles/withStyles';
import background from '../assets/images/background.png';

const styles = {
  root: {
  },
  loginPage: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#f0f0f0'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  form: {
    position: 'absolute',
    width: 250,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.15); //1px 1px 5px rgba(0,0,0,.2)',
    background: '#f8f8f8',
    borderRadius: 4,
    padding: '30px 35px',
    textAlign: 'right'
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: '2.4rem',
    paddingSottom: '0.5rem',
    margin: '0 0 0.5rem 0',
    fontWeight: 'bold',
    // color: theme.Colors.blue.primary.main,
    borderBottom: '1px solid #969696',
    textAlign: 'center',
  },
  cancel: {
    display: 'inline-block',
    marginRight: 10,
    fontSize: '0.7rem',
    // color: theme.Colors.blue.primary.main,
  },
  resetBtn: {
    marginTop: 15
  },
  passwordOptions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    padding: '0.3rem 0 0.7rem',
    alignItems: 'center'
  },
  forgot: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: '0.8rem',
    color: '#969696',
    textAlign: 'left'
  },
  remember: {
    flexGrow: 1,
    flexShrink: 1,
    fontSize: '0.8rem',
    color: '#969696',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  forgotLink: {
    // color: theme.Colors.blue.primary.main
  },
  rememberIcon: {
    marginLeft: 5
  },
  submitBtn: {
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  errorMessage: {
    display: 'block',
    marginTop: 10,
    // color: theme.Colors.red.primary.main,
    fontSize: '.8rem',
    textAlign: 'left'
  }
};

export type LoginProps =
  InjectedApiProps &
  WithStyles<keyof typeof styles>;

type State = {
  username?: string;
  password?: string;
  forgot?: boolean;
  showPassword?: boolean
  usernameFocussed?: boolean;
  rememberMe?: boolean;
  error?: boolean;
  errorMessage?: React.ReactNode;
};

class LoginPage extends React.Component<LoginProps, State> {
  constructor(props: LoginProps) {
    super(props);
    const { apiCtx: { user } } = this.props;

    this.state = {
      username: (user && user.UserName) || undefined
    };
    this._handleShowPasswordClick = this._handleShowPasswordClick.bind(this);
    this._handleShowPasswordMouseDown = this._handleShowPasswordMouseDown.bind(this);
    this._handleUsernameChange = this._handleUsernameChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handleLoginSubmit = this._handleLoginSubmit.bind(this);
    // this._handleResetSubmit = this._handleResetSubmit.bind(this);
    this._handleForgotPassword = this._handleForgotPassword.bind(this);
    this._handleRmemberMeCLick = this._handleRmemberMeCLick.bind(this);
  }

  render() {

    const { password, username, forgot, rememberMe, error, errorMessage } = this.state;
    const { apiCtx: { fetching }, classes } = this.props;

    // const usernameTitle = formatMessage(logInMessages.usernameTitle);
    // const passwordTitle = formatMessage(logInMessages.passwordTitle);
    return (
      <div className={classes.loginPage}>
        <div className={classes.background}>
          <img src={background} className={classes.backgroundImage} />
        </div>
        <form
          className={classes.form}
          onSubmit={
            this.state.forgot
              ? this._handleResetSubmit
              : this._handleLoginSubmit
          }
        >
          <Typography className={classes.title} variant='h2'>
            Login
          </Typography>

          <TextField
            label='User name'
            onChange={(value) => this._handleUsernameChange(value.target.value)}
            value={username || ''}
            fullWidth={true}
            inputProps={{ autoComplete: 'username' }}
          />

          {!this.state.forgot && (
            <React.Fragment>
              <FormControl fullWidth={true}>
                <InputLabel htmlFor='password'>password</InputLabel>
                <Input
                  id='password'
                  type={this.state.showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  value={password || ''}
                  onChange={(value) => this._handlePasswordChange(value.target.value)}
                  endAdornment={(
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Toggle password visibility'
                        onClick={this._handleShowPasswordClick}
                        onMouseDown={this._handleShowPasswordMouseDown}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
              <div className={classes.passwordOptions}>
                <Typography className={classes.forgot}>
                  Forget
                </Typography>
                <Typography>
                  <a
                    className={classes.remember}
                    onClick={this._handleRmemberMeCLick}
                  >
                    remember me
                    <span className={classes.rememberIcon}>
                      {rememberMe ? <CheckCircle /> : <RadioButtonUnchecked />}
                    </span>
                  </a>
                </Typography>
              </div>
            </React.Fragment>
          )}
          {forgot && (
            <Button onClick={() => this._handleBackClick()}>
              back
            </Button>
          )}
          {fetching ?
            (
              <Button
                variant='contained'
                color='primary'
                disabled={true}
              >
                loading
              </Button>
            ) :
            (
              <Button
                type='submit'
                variant='contained'
                color='primary'
              >
                {forgot ?
                  'reset' :
                  'submit'
                }
              </Button>
            )}
          {error && (
            <div className={classes.errorMessage}>
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    );
  }
  private _handleShowPasswordClick() {
    this.setState(prev => ({
      showPassword: !prev.showPassword
    }));
  }
  private _handleShowPasswordMouseDown(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
  }
  private _handleRmemberMeCLick() {
    this.setState(prev => ({
      rememberMe: !prev.rememberMe
    }));
  }

  private _handleLoginSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { username, password } = this.state;
    const { apiCtx } = this.props;
    if (!username || !password) {
      this.setState(() => ({
        error: true,
        errorMessage: 'imcomplete' // formatMessage(logInMessages.imcomplete)
      }));

      return;
    }
    apiCtx
      .login(username, password)
      .then((result) => {
        if (!result) {
          this.setState(() => ({
            error: true,
            errorMessage: 'failed' // formatMessage(logInMessages.failed)
          }));
        }
      });
  }

  private _handleResetSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // const { username } = this.state;
    // const { authCtx } = this.props;
    // if (username) {
    //   authCtx.reset(username);
    // }
  }

  private _handlePasswordChange(value: string) {
    this.setState(() => ({
      password: value
    }));
  }

  private _handleUsernameChange(value: string) {
    this.setState(() => ({
      username: value
    }));
  }

  private _handleBackClick() {
    this.setState(() => ({
      forgot: false
    }));
  }
  private _handleForgotPassword(e: React.FormEvent<HTMLAnchorElement>) {
    this.setState(prev => ({
      forgot: !prev.forgot
    }));
  }
}

export default withStyles(styles as StyleRules<keyof typeof styles>)(withApi(LoginPage));