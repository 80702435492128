import * as React from 'react';
import { useEffect, FC, useRef } from 'react';
import * as ReactDOM from 'react-dom';
import { underline } from '../constants/stylesheet';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import loadjs from 'loadjs';
import { useApi } from '../@context/Api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: theme.spacing(4, 4, 0, 4)
        },
        navContainer: {
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            paddingRight: 200
        },
        link: {
            position: 'relative',
            display: 'flex',
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        linkText: {
            position: 'relative',

            '&:hover:after': underline
        },
        active: {
            '& $linkText:after': underline
        },
        superScript: {
            fontSize: '80%',
            marginLeft: theme.spacing(.75),
            fontWeight: 600,
            display: 'inline-block',
            transform: 'translateY(-6px)'
        },
        user: {
            position: 'absolute',
            top: 5,
            right: 0,
            cursor: 'pointer',
        },
        userName: {
            position: 'relative',
            '&:after': underline
        },
        userMenuOuter: {
            margin: theme.spacing(0.5, 0, 0, 0),
            padding: theme.spacing(2, 2, 2, 2),
        },
        userMenu: {
            position: 'relative',
            whiteSpace: 'nowrap',

            '&:hover:after': underline
        },
        changeInput: {
            marginBottom: 10
        },
        saveButton: {
            boxShadow: 'none'
        }
    }));

type Props = { invoice: InvoiceToPay, done: () => void };
let dependencyRequested = false;

type PaypalSettings = {
    sandbox: string;
    production: string;
    Environment: 'production' | 'sandbox';
};

const PayPalBtn: FC<{ settings: PaypalSettings; Btn: PaypalReactButton; invoice: InvoiceToPay, done: () => void }> = ({
    done,
    settings: { Environment, sandbox, production }, Btn, invoice: { Id, PaidAmount, Amount, Currency }
}) => {
    const { fetch } = useApi();
    const payment = (data: PaypalData, actions: PaypalActions) => {
        return actions.payment.create({
            transactions: [
                {
                    amount: {
                        total: Amount - PaidAmount,
                        currency: (Currency && Currency.toUpperCase()) || 'EUR'
                    }
                }
            ]
        });
    };

    const onAuth = async (data: PaypalData, actions: PaypalActions) => {
        await actions.payment.execute();
        await fetch<string>('billing/paypal', {
            method: 'POST',
            body: JSON.stringify({
                InvoiceId: Id,
                PaymentId: data.paymentID,
                PayerId: data.payerID
            })
        });
        done();
    };

    // const onCancel = (data: PaypalData) => {
    //     // tslint:disable-next-line:no-console
    //     console.log('The payment was cancelled!');
    // };

    return (
        <Btn
            env={Environment}
            style={{ shape: 'rect', size: 'large', color: 'blue' }}
            commit={true}
            client={{ sandbox, production }}
            payment={payment}
            onAuthorize={onAuth}
        />
    );
};
const PayByPaypal: FC<Props> = ({ invoice, children, done }) => {
    const classes = useStyles({});
    const [payPalBtnLoaded, setPayPalBtnLoaded] = React.useState(false);
    const payPalBtn = useRef<PaypalReactButton>();
    const { fetch } = useApi();
    const [settings, setSettings] = React.useState<PaypalSettings>();

    const onpaypalScriptLoad = () => {
        /*eslint-disable no-undef*/
        const component = paypal
            .Button.driver('react', { React: React, ReactDOM: ReactDOM });
        /*eslint-enable no-undef*/
        dependencyRequested = true;
        payPalBtn.current = component;
        setPayPalBtnLoaded(true);
    };

    useEffect(
        () => {
            if (!dependencyRequested) {
                loadjs('https://www.paypalobjects.com/api/checkout.js', {
                    success: onpaypalScriptLoad,
                    before: (path, tag) => {
                        tag.setAttribute('data-version-4', '');
                        return true;
                    }
                });
            } else {
                onpaypalScriptLoad();
            }

            fetch<PaypalSettings>('billing/paypal', { method: 'GET' }).then(setSettings);
        },
        [fetch]
    );

    return (
        <div className={classes!.container}>
            {(payPalBtnLoaded &&
                payPalBtn.current &&
                settings &&
                <PayPalBtn {...{ Btn: payPalBtn.current, settings, invoice, done }} />) || <>loading...</>}
            {children}
        </div>
    );
};

export default PayByPaypal;