import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
type Props = {
    field: FieldDto;
    value: string;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            color: '#858585'
        },
        labelNovalue: {
            color: '#cccccc'
        },
        labelUnsaved: {
            color: '#3f51b5',
            fontWeight: 600
        },
    }));

const NumberField: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    const {
        value, field, setChanges, changes
    } = props;

    return (
        <TextField
            autoComplete="off"
            type="number"
            fullWidth={true}
            value={value}
            label={field.Legend}
            onChange={(e) => setChanges({ ...changes, [field.PropertyName]: e.target.value })}
            InputLabelProps={{
                classes: { 
                    root: classNames(
                        classes!.label,
                        changes[field.PropertyName] && classes!.labelUnsaved,
                        !changes[field.PropertyName] && classes!.labelNovalue,
                    )
                }
            }}
        />
    );
};

export default NumberField;