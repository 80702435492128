import * as React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextInputField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { OptionProps } from 'react-select/src/components/Option';
import { NoticeProps, MenuProps } from 'react-select/src/components/Menu';
import { ControlProps } from 'react-select/src/components/Control';
import { PlaceholderProps } from 'react-select/src/components/Placeholder';
import { SingleValueProps } from 'react-select/src/components/SingleValue';
import { ValueContainerProps } from 'react-select/src/components/containers';
import { ValueType } from 'react-select/src/types';

type TOption = {
    label: string;
    entry: EntryDto;
    value: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        chipFocused: {
            backgroundColor: emphasize(
                theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
                0.08,
            ),
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            bottom: 6,
            fontSize: 16,
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        divider: {
            height: theme.spacing(2),
        },
    }),
);

const NoOptionsMessage: React.FunctionComponent<NoticeProps<TOption>> = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & React.HTMLAttributes<HTMLDivElement>;

const inputComponent: React.FunctionComponent<InputComponentProps> = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />;
};

const Control: React.FunctionComponent<ControlProps<TOption>> = (props) => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;
    return (
        <TextInputField
            fullWidth={true}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
};

const Option: React.FunctionComponent<OptionProps<TOption>> = (props) => {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
};

const Placeholder: React.FunctionComponent<PlaceholderProps<TOption>> = (props) => {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
};

const SingleValue: React.FunctionComponent<SingleValueProps<TOption>> = (props) => {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
};

const ValueContainer: React.FunctionComponent<ValueContainerProps<TOption>> = (props) => {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const Menu: React.FunctionComponent<MenuProps<TOption>> = (props) => {
    return (
        <Paper square={true} className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
};

const components = {
    Control,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
};
type SelectEntryProps = {
    label?: string;
    value?: EntryDto;
    preload?: boolean;
    onCreate?: (query?: string) => void;
    change: (entry?: EntryDto) => void;
    list: (query: string) => Promise<EntryDto[]>;
};

const SelectEntry: React.FunctionComponent<SelectEntryProps> = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [defaultOptions, setDefaultOptions] = React.useState<TOption[]>();

    const { onCreate, change, list, value, label, preload } = props;

    const selectStyles = {
        input: (base: React.CSSProperties) => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
                font: 'inherit',
            },
        }),
    };

    const onChange =
        (v: ValueType<TOption>) => change(v && (v as TOption).entry || undefined);
    const loadOptions = (query: string) =>
        list(query).then(entries => entries.map(e => ({ label: e.PreviewTitle, value: e.Id, entry: e })));

    React.useEffect(
        () => {
            if (preload) {
                loadOptions('').then(opts => setDefaultOptions(opts));
            }
        },
        []
    );

    const createLabel = (input: string) =>
        `Create ${input && `'${input}'` || label && `new ${label.toLowerCase()}`}…`;
    return (
        <div className={classes.root}>
            <NoSsr>
                {onCreate &&
                    <AsyncCreatableSelect
                        loadOptions={loadOptions}
                        onCreateOption={onCreate}
                        isMulti={false}
                        classes={classes}
                        styles={selectStyles}
                        components={components}
                        value={value && { label: value.PreviewTitle, value: value.Id, entry: value }}
                        onChange={onChange}
                        placeholder={`Select ${label}`}
                        defaultOptions={defaultOptions}
                        isValidNewOption={() => true}
                        formatCreateLabel={createLabel}
                    />
                }
                {!onCreate &&
                    <AsyncSelect
                        loadOptions={loadOptions}
                        classes={classes}
                        isMulti={false}
                        styles={selectStyles}
                        components={components}
                        value={value && { label: value.PreviewTitle, value: value.Id, entry: value }}
                        onChange={onChange}
                        placeholder={`Select ${label}`}
                        defaultOptions={defaultOptions}
                    />
                }
            </NoSsr>
        </div>
    );
};

export default SelectEntry;