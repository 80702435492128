import React, { useEffect, useContext, FC, ReactNode, useRef, useState } from 'react';
import { EntryContext } from '../@context/Entry';
import { List, ListItem } from '@material-ui/core';
import { useApi } from '../@context/Api';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import VirtualList from 'react-tiny-virtual-list';
import Loading from './Loading';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            width: '100%',
            padding: 0,
            height: '100%'
        },
        listItem: {
            '&:hover': {
                background: 'none'
            }
        }
    }));

const sortClient = (a: EntryDto, b: EntryDto) => {
    if (a.PreviewTitle > b.PreviewTitle) {
        return 1;
    }
    if (a.PreviewTitle < b.PreviewTitle) {
        return -1;
    }
    return 0;
};

type ListViewItemsProps = {
    type: string;
    query?: string;
    itemRenderer: (entry: EntryDto) => ReactNode;
};

type Props = ListViewItemsProps;

const ListViewItems: FC<Props> = ({ type, query, itemRenderer, children }) => {
    const { open } = useContext(EntryContext);
    const { getAll, lastChange } = useApi();
    const classes = useStyles();
    const [entries, setEntries] = useState<EntryDto[]>([]);
    const [loading, setloading] = useState(true);
    const container = useRef<HTMLUListElement>(null);
    const currentQuery = useRef(query);

    useEffect(
        () => {
            const effect = async () => {
                currentQuery.current = query;
                const _entries = await getAll(type, query);
                if (type === 'client') {
                    _entries.sort(sortClient);
                }
                if (currentQuery.current === query) {
                    await setEntries(_entries);
                }
                setloading(false);
            };
            effect();
        },
        [query]
    );

    useEffect(
        () => {
            const toAdd = lastChange && lastChange.Added && lastChange.Added.filter(a => a.Type.toLowerCase() === type);
            if (toAdd && toAdd.length) {
                // add them to the list 
                setEntries([...toAdd as EntryDto[], ...entries]);
            }
            const toModify =
                lastChange && lastChange.Modified && lastChange.Modified.filter(a => a.Type.toLowerCase() === type);
            if (toModify && toModify.length) {
                // add them to the list 
                setEntries(entries.map(e => {
                    const existing = toModify.find(m => m.Id === e.Id) as EntryDto;
                    return existing || e;
                }));
            }
            const toDelete =
                lastChange && lastChange.Deleted && lastChange.Deleted.filter(a => a.Type.toLowerCase() === type);
            if (toDelete && toDelete.length) {
                // add them to the list 
                setEntries(entries.filter(e => !toDelete.some(d => d.Id === e.Id)));
            }
        },
        [lastChange]
    );

    return (
        <List dense={true} className={classes!.container} ref={container}>
            {loading && <Loading />}
            <VirtualList
                width='100%'
                height={container.current && container.current.clientHeight || 0}
                itemCount={entries ? entries.length : 0}
                itemSize={50} // Also supports variable heights (array or function getter)
                renderItem={({ index, style }) => (
                    <ListItem
                        key={entries[index].Id}
                        button={true}
                        component='li'
                        onClick={() => open(entries[index].Id, entries[index].Id, entries[index].Type)}
                        classes={{ button: classes!.listItem }}
                        style={style}
                    >
                        {itemRenderer(entries[index])}
                    </ListItem>
                )}
            />
            {children}
        </List>
    );
};

export default ListViewItems;