import * as React from 'react';
import {
  Typography,
  Popover,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@material-ui/core';
import { Link, Route, NavLink } from 'react-router-dom';
import { underline } from '../constants/stylesheet';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ApiContext, ApiProvider } from '../@context/Api';
import useReactRouter from 'use-react-router';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(4, 4, 0, 4),
    },
    navContainer: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'baseline',
      paddingRight: 200,
    },
    link: {
      position: 'relative',
      display: 'flex',
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      color: theme.palette.neutral.main,
    },
    linkText: {
      position: 'relative',

      '&:hover:after': underline,
    },
    active: {
      '& $linkText:after': underline,
      color: 'black',
    },
    superScript: {
      fontSize: '80%',
      marginLeft: theme.spacing(0.75),
      fontWeight: 600,
      display: 'inline-block',
      transform: 'translateY(-6px)',
      color: theme.palette.neutral.main,

      '$active &': {
        color: 'black',
      }
    },
    user: {
      position: 'absolute',
      top: 5,
      right: 0,
      cursor: 'pointer',
    },
    userName: {
      position: 'relative',
      '&:after': underline,
    },
    userMenuOuter: {
      margin: theme.spacing(0.5, 0, 0, 0),
      padding: theme.spacing(2, 2, 2, 2),
    },
    userMenu: {
      position: 'relative',
      whiteSpace: 'nowrap',

      '&:hover:after': underline,
    },
    changeInput: {
      marginBottom: 10,
    },
    saveButton: {
      boxShadow: 'none',
    },
  })
);
type TMenu = {
  legend: string;
  path: string;
  list?: string;
  filters?: { legend: string; query: string }[];
  Count: { type: string }[];
  children?: TMenu[];
};
export const Menus: TMenu[] = [
  {
    legend: 'Billing',
    path: '/billing',
    Count: [{ type: 'Invoice' }, { type: 'Offer' }],
    children: [
      {
        Count: [{ type: 'Invoice' }],
        legend: 'Invoices',
        path: '/billing/invoices/list',
        list: 'invoice',
        filters: [
          { legend: 'Due', query: 'State:Due' },
          { legend: 'OverDue', query: 'State:OverDue' },
          { legend: 'Paid', query: 'State:Paid' },
        ],
      },
      {
        Count: [{ type: 'Offer' }],
        legend: 'Offers',
        path: '/billing/offers/list',
        list: 'offer',
      },
      {
        Count: [{ type: 'bankaccount' }],
        legend: 'Accounts',
        path: '/billing/accounts/list',
        list: 'bankaccount',
      },
      {
        Count: [{ type: 'BillingItemTemplate' }],
        legend: 'Billing Item Templates',
        path: '/billing/BillingItemTemplate/list',
        list: 'BillingItemTemplate',
      },
    ],
  },
  {
    legend: 'Services',
    path: '/services',
    Count: [
      { type: 'Website' },
      { type: 'DomainName' },
      { type: 'Certificate' },
      { type: 'EmailAccount' },
    ],
    children: [
      {
        Count: [{ type: 'Website' }],
        legend: 'Websites',
        path: '/services/website/list',
        list: 'website',
      },
      {
        Count: [{ type: 'DomainName' }],
        legend: 'Domain Names',
        path: '/services/domainname/list',
        list: 'domainname',
      },
      {
        Count: [{ type: 'Certificate' }],
        legend: 'Certificates',
        path: '/services/certificate/list',
        list: 'certificate',
      },
      {
        Count: [{ type: 'EmailAccount' }],
        legend: 'Email Accounts',
        path: '/services/emailaccount/list',
        list: 'emailaccount',
      },
    ],
  },
  {
    Count: [{ type: 'Subscription' }],
    legend: 'Subscriptions',
    path: '/subscription/list',
    list: 'subscription',
    filters: [
      { legend: 'Init', query: 'State=Init' },
      { legend: 'Ok', query: 'State=Ok' },
      { legend: 'PendingPayment', query: 'State=PendingPayment' },
      { legend: 'Revoked', query: 'State=Revoked' },
      { legend: 'Done', query: 'State=Done' },
    ],
  },
  {
    Count: [{ type: 'Client' }],
    legend: 'Clients',
    path: '/client/list',
    list: 'client',
  },
];
export type MenuProps = {};
type Props = MenuProps;
type TCount = {
  type: string;
  query?: string;
};

const Menu: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const { addCount, counts, user, logout } = React.useContext(ApiContext);
  const { location } = useReactRouter();
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>();
  const reduceCount = (p: TCount[], c: { Count?: TCount[] }) =>
    c.Count ? [...p, ...c.Count] : p;
  React.useEffect(() => {
    const cs = Menus.reduce((p: TCount[], c) => {
      const result = reduceCount(p, c);
      return (c.children && c.children.reduce(reduceCount, result)) || result;
    }, []);
    cs.forEach((c) => addCount(c.type, c.query));
  }, []);

  const getCount = (m: { Count?: TCount[] }) => {
    if (!m.Count) {
      return null;
    }
    let count = 0;
    m.Count.forEach((c) => {
      const id = ApiProvider.GetCountId(c.type, c.query);
      count += counts[id];
    });

    if (!count) {
      return null;
    }
    return (
      <Typography>
        <span className={classes!.superScript}>{count}</span>
      </Typography>
    );
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const openChangePasswordDialog = () => {
    setDialogOpen(true);
  };

  const closeChangePasswordDialog = () => {
    setDialogOpen(false);
  };

  const saveChangePasswordDialog = () => {
    return;
  };

  return (
    <div className={classes!.container}>
      <nav className={classes!.navContainer}>
        {Menus.map((m) => (
          <NavLink
            className={classnames(
              classes!.link,
              location.pathname.indexOf(m.path) === 0 && classes!.active
            )}
            key={m.path}
            to={m.children ? m.children[0]!.path : m.path}
          >
            <Typography variant='h1' className={classes!.linkText}>
              {m.legend}
            </Typography>
            {getCount(m)}
          </NavLink>
        ))}
        {user && (
          <div className={classes!.user}>
            <Typography
              className={classes!.userName}
              variant='h2'
              onClick={handleClick}
            >
              {`${user.FirstName} ${user.LastName}`}
            </Typography>
            <Popover
              className={classes!.userMenuOuter}
              classes={{ paper: classes!.userMenuOuter }}
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography variant='body1' style={{ marginBottom: 4 }}>
                <a
                  className={classes!.userMenu}
                  onClick={openChangePasswordDialog}
                >
                  Change password
                </a>
              </Typography>
              <Typography variant='body1'>
                <a className={classes!.userMenu} onClick={logout}>
                  Log out
                </a>
              </Typography>
            </Popover>
          </div>
        )}
      </nav>
      {Menus.map((m) => (
        <Route
          key={m.path}
          path={m.path}
          render={() =>
            m.children ? (
              <nav className={classes!.navContainer}>
                {m.children.map((c) => (
                  <Link
                    className={classnames(
                      classes!.link,
                      location.pathname.indexOf(c.path) === 0 && classes!.active
                    )}
                    to={c.path}
                    key={c.path}
                  >
                    <Typography variant='h1' className={classes!.linkText}>
                      {c.legend}
                    </Typography>
                    {getCount(c)}
                  </Link>
                ))}
              </nav>
            ) : null
          }
        />
      ))}
      <Dialog open={!!dialogOpen} onClose={closeChangePasswordDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            className={classes!.changeInput}
            label='Current password'
            autoFocus={true}
            type='password'
            fullWidth={true}
          />
          <TextField
            className={classes!.changeInput}
            label='New password'
            type='password'
            fullWidth={true}
          />
          <TextField
            className={classes!.changeInput}
            label='Confirm new password'
            type='password'
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeChangePasswordDialog} variant='text'>
            Cancel
          </Button>
          <Button
            className={classes!.saveButton}
            onClick={saveChangePasswordDialog}
            variant='contained'
            color='primary'
          >
            Change password
          </Button>
        </DialogActions>
      </Dialog>
      {props.children}
    </div>
  );
};

export default Menu;
