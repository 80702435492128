import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC = ({ children }) => {
    const id = 'loadingContainer';
    const body = document.body;
    let loadingContainer = document.getElementById(id);

    if (!loadingContainer) {
        loadingContainer = document.createElement('div');
        loadingContainer.id = id;
    }

    useEffect(
        () => {
            if (loadingContainer) {
                body.appendChild(loadingContainer);
            }

            return function cleanup() {
                // On cleanup remove the modal container
                if (loadingContainer) {
                    body.removeChild(loadingContainer);
                }
            };
        },
        []
    ); 

    return createPortal(children, loadingContainer);
};
export default Portal;