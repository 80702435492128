import * as React from 'react';

import { ApiContext } from '../../@context/Api';

import { EntryContext } from '../../@context/Entry';

import {
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Button,
    Typography,
    makeStyles,
    createStyles
} from '@material-ui/core';

import EntryIcon from '../EntryIcon';
import ProductDetail from '../ProductDetail';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            position: 'fixed',
            bottom: 13,
            right: 30
        },
        saveButton: {
            boxShadow: 'none'
        },
        dialog: {
            width: 500
        }
    }),
);

const SubscriptionCreator: React.FunctionComponent<{}> = () => {
    const [productTypes, setProductTypes] = React.useState<string[]>([]);
    const [productDetailType, setproductDetailType] = React.useState<string>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const { create, fetch } = React.useContext(ApiContext);
    const { open } = React.useContext(EntryContext);

    React.useEffect(
        () => {
            fetch<string[]>('products', { method: 'GET' }).then(types => setProductTypes(types));
        },
        []
    );

    const handleClose = () => {
        setModalOpen(false);
        setproductDetailType('');
    };

    const classes = useStyles();

    const _empty = async () => {
        const added =
            await create('subscription');
        await open('', added.Id, added.Type);
    };

    const handleOpen = async () => {
        if (productTypes.length > 1) {
            setModalOpen(true);

        } else if (productTypes.length === 1) {
            await setproductDetailType(productTypes[0]);
        }
    };

    return (
        <>
            <div className={classes.container}>
                <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleOpen}
                >
                    Add
                </Button>
            </div>
            <Dialog
                open={modalOpen}
                onClose={handleClose}
            >
                <DialogTitle disableTypography={true} id="simple-dialog-title">
                    <Typography
                        variant="h1"
                    >
                        New Subscription
                    </Typography>
                </DialogTitle>
                <List>
                    {productTypes.map(productType => (
                        <ListItem button={true} onClick={() => setproductDetailType(productType)} key={productType}>
                            <ListItemAvatar>
                                <Avatar>
                                    <EntryIcon entry={{ Type: productType }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={productType} />
                        </ListItem>
                    ))}
                    <ListItem button={true} onClick={() => _empty()} key="empty">
                        <ListItemAvatar>
                            <Avatar>
                                <EntryIcon entry={{ Type: 'subscription' }} />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="empty subscription..." />
                    </ListItem>
                </List>
            </Dialog>
            <Dialog
                open={!!productDetailType}
                onClose={handleClose}
                PaperProps={{
                    classes: { root: classes.dialog }
                }}
            >
                {productDetailType &&
                    <ProductDetail {...{ type: productDetailType, close: handleClose }} />}
            </Dialog>
        </>
    );
};

export default SubscriptionCreator;