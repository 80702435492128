import * as React from 'react';
import {
    Explore, EventAvailable, EventBusy,
    Event, Person, AssignmentTurnedIn, AssignmentLate,
    Assignment, MonetizationOn, CreditCard, CloudCircle,
    AccountBalance, SwapHoriz, Computer, Security, Cloud,
    Lock, AccountBalanceWallet, LocalShipping, AlternateEmail
} from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

export type EntryIconProps = SvgIconProps & {
    entry: Partial<EntryDto>;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        'none': {
            fill: theme.palette.neutral.main
        },
        'info': {
            fill: theme.palette.primary.dark
        },
        'alert': {
            fill: theme.palette.primary.main
        },
        'loading': {
            fill: theme.palette.primary.dark
        },
        'error': {
            fill: theme.palette.error.dark
        },
        'ok': {
            fill: theme.palette.success.dark
        }
    }),
);

type Props =
    EntryIconProps;

const EntryIcon: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { entry, ...rest } = props;

    switch (entry.Type) {
        case 'Website':
            return <Cloud color='inherit' className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'BankAccount':
            return (
                <AccountBalanceWallet
                    color='inherit'
                    className={entry.PreviewState && classes[entry.PreviewState]}
                    {...rest}
                />
            );
        case 'DomainName':
            return <Explore color='inherit' className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'Https':
            return <Lock color='inherit' className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'Invoice':
        case 'Offer':
            switch (entry.PreviewState) {
                case 'ok':
                    return (
                        <AssignmentTurnedIn
                            color='inherit'
                            className={entry.PreviewState && classes[entry.PreviewState]}
                            {...rest}
                        />
                    );
                case 'error':
                    return <AssignmentLate className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                default:
                    return <Assignment className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
            }
        case 'Payment': {
            const payment = entry as Payment;
            switch (payment.Method) {
                case 'Transfer':
                    return <SwapHoriz className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                case 'Cash':
                    return <MonetizationOn className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                case 'Paypal':
                    return <CloudCircle className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                case 'Stripe':
                    return <CreditCard className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                default:
                    return <AccountBalance className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
            }
        }
        case 'Client':
        case 'Person':
            return <Person className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'WebServer':
            return <Computer className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'Subscription':
            switch (entry.PreviewState) {
                case 'ok':
                    return <EventAvailable className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                case 'error':
                    return <EventBusy className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
                default:
                    return <Event className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
            }
        case 'Certificate':
            return <Security className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'Shipment':
            return <LocalShipping className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        case 'EmailAccount':
            return <AlternateEmail className={entry.PreviewState && classes[entry.PreviewState]} {...rest} />;
        default:
            return null;
    }
};

export default EntryIcon;