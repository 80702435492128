import * as React from 'react';
import {
	Grid,
	makeStyles,
	Theme,
	createStyles,
	Button,
} from '@material-ui/core';
import { useApi } from '../../@context/Api';
import { useState, FC } from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		btnAddContainer: {
			width: '100%',
			padding: theme.spacing(0, 1, 0, 1),
			display: 'flex',
		},
		btnSend: {
			marginRight: 'auto',
			boxShadow: 'none',
		},
		btnCancel: {
			marginRight: 'auto',
			boxShadow: 'none',
			color: 'white',
			backgroundColor: theme.palette.error.main,

			'&:hover': {
				backgroundColor: theme.palette.error.dark,
			},
		},
	})
);
type Props = {
	entry: EmailShipment;
	changes: Partial<EmailShipment>;
	refresh: () => void;
};

type EmailState = 'sending' | 'canceling' | undefined;

const EmailShipmentUtilities: FC<Props> = ({
	entry,
	changes,
	refresh,
	children,
}) => {
	const classes = useStyles();
	const canSendInitValue =
		Object.keys(changes).length === 0 &&
		!!entry.RecipientEmail &&
		!entry.SentDate &&
		!entry.ScheduledDate;

	const { Type, Id } = entry;
	const { fetch } = useApi();
	const [canSend, setCanSend] = useState(canSendInitValue);
	const [emailState, setEmailState] = useState<EmailState>();
	const [emailTimeOut, setEmailTimeOut] = useState<NodeJS.Timeout | null>();

	const sendEmail = () => {
		setEmailState('sending');

		const send = () => {
			fetch(`billing/${Type}/${Id}/email/client`, {})
				.then(() => refresh())
				.finally(() => {
					setEmailTimeOut(null);
					setEmailState(undefined);
				});
		};

		const timeOut = setTimeout(() => send(), 5000);
		setEmailTimeOut(timeOut);
	};

	const cancelEmail = () => {
		if (!emailTimeOut || emailState !== 'sending') {
			return;
		}

		clearTimeout(emailTimeOut);
		setEmailState(undefined);
	};

	React.useEffect(() => {
		const v =
			Object.keys(changes).length === 0 &&
			!!entry.RecipientEmail &&
			!entry.SentDate &&
			!entry.ScheduledDate;

		setCanSend(v);
	}, [changes]);

	return (
		<Grid item={true} xs={12}>
			<Grid container={true} spacing={3}>
				<div className={classes.btnAddContainer}>
					{emailState === undefined && (
						<Button
							className={classes.btnSend}
							aria-label={`Send ${Type} email`}
							onClick={sendEmail}
							color='primary'
							variant='contained'
							size='small'
							disabled={!canSend}
						>
							Send document
						</Button>
					)}
					{emailState === 'sending' && (
						<Button
							className={classes.btnCancel}
							aria-label={`Send ${Type} email`}
							onClick={cancelEmail}
							variant='contained'
							size='small'
						>
							Cancel email
						</Button>
					)}
				</div>
			</Grid>
			{children}
		</Grid>
	);
};

export default EmailShipmentUtilities;
