import React, { FC } from 'react';
import { ListItemText, ListItemIcon, makeStyles, createStyles } from '@material-ui/core';
import EntryIcon from './EntryIcon';
import { listItemIconMinWidth } from '../constants/stylesheet';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            minWidth: listItemIconMinWidth
        },
        primaryText: {
            fontSize: '1.2rem'
        },
        secondaryText: {
            fontSize: '.7rem',
            color: 'black'
        },
    }),
);

const PreviewRenderer: FC<{ entry: EntryDto }> = ({ entry, entry: { Type } }) => {
    const classes = useStyles();
    switch (Type) {

        default:
            return (
                <>
                    <ListItemIcon classes={{ root: classes!.icon }}>
                        <EntryIcon entry={entry} />
                    </ListItemIcon>
                    <ListItemText
                        classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}
                        primary={entry.PreviewTitle}
                        secondary={entry.PreviewDescription}
                    />
                </>
            );
    }
};

export default PreviewRenderer;