import * as React from 'react';
import { InjectedEntryProps, withEntry } from '../@context/Entry';
import { withApi, InjectedApiProps } from '../@context/Api';
import {
    withStyles,
    StyledComponentProps
} from '@material-ui/core';

import EntryField from './Fields/EntryField';
import ListEntryField from './Fields/ListEntryField';
import SelectField from './Fields/SelectField';
import RuntimeVersionField from './Fields/RuntimeVersionField';
import NumberField from './Fields/NumberField';
import CurrencyField from './Fields/CurrencyField';
import DomainNameField from './Fields/DomainNameField';
import PaymentMethodField from './Fields/PaymentMethodField';
import PaymentStatusField from './Fields/PaymentStatusField';
import BillingPeriodField from './Fields/BillingPeriodField';
import DateField from './Fields/DateField';
import DateTimeField from './Fields/DateTimeField';
import InvoiceLanguageField from './Fields/InvoiceLanguageField';
import BillingClientField from './Fields/BillingClientField';
import TextInputField from './Fields/TextInputField';
import SwitchField from './Fields/SwitchField';

const styles = {
    underline: {
        '&:before': {
            borderBottomColor: '#3f51b5'
        }
    },
    label: {
        color: '#858585'
    },
    labelNovalue: {
        color: '#cccccc'
    },
    labelUnsaved: {
        color: '#3f51b5',
        fontWeight: 600
    },
};

type FieldProps = {
    field: FieldDto;
    type: string;
    id: string;
    path: string;
    preview: EntryDto;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => Promise<void>;
    refresh: () => Promise<void>;
};

type Props =
    FieldProps &
    InjectedEntryProps &
    InjectedApiProps &
    StyledComponentProps<keyof typeof styles>;

const Field: React.FunctionComponent<Props> = (props) => {
    const {
        field,
        preview,
        setChanges,
        changes,
        path,
        id,
        type,
        refresh
    } = props;

    switch (field.FieldType) {
        case 'Text':
            return (
                <TextInputField
                    field={field}
                    value={preview[field.PropertyName] as string}
                    changes={changes}
                    setChanges={setChanges}
                />
            );
        case 'Date': {
            return (
                <DateField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        }

        case 'DateTime': {
            return (
                <DateTimeField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        }
        case 'Switch': {
            return (
                <SwitchField
                    field={field}
                    changes={changes}
                    value={!!preview[field.PropertyName]}
                    setChanges={setChanges}
                />
            );
        }
        case 'BillingPeriod':
            return (
                <BillingPeriodField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'InvoiceLanguage':
            return (
                <InvoiceLanguageField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'Currency':
            return (
                <CurrencyField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'Number':
            return (
                <NumberField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'RuntimeVersion':
            return (
                <RuntimeVersionField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'DomainName':
            return (
                <DomainNameField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'PaymentMethod':
            return (
                <PaymentMethodField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'PaymentStatus':
            return (
                <PaymentStatusField
                    field={field}
                    changes={changes}
                    value={preview[field.PropertyName] as string}
                    setChanges={setChanges}
                />
            );
        case 'Select':
            return (
                <SelectField
                    setChanges={setChanges}
                    field={field}
                    id={id}
                    changes={changes}
                    type={type}
                    path={path}
                    refresh={refresh}
                    value={preview[field.PropertyName] as EntryDto}
                />
            );
        case 'Entry': {

            return (
                <EntryField
                    setChanges={setChanges}
                    field={field}
                    id={id}
                    changes={changes}
                    type={type}
                    path={path}
                    refresh={refresh}
                    value={preview[field.PropertyName] as EntryDto}
                />
            );
        }
        case 'BillingClient': {
            return (
                <BillingClientField
                    setChanges={setChanges}
                    field={field}
                    id={id}
                    changes={changes}
                    type={type}
                    path={path}
                    refresh={refresh}
                    value={preview[field.PropertyName] as EntryDto}
                />
            );
        }
        case 'EntryList': {
            const values = preview[field.PropertyName] as EntryDto[];
            return (
                <ListEntryField
                    setChanges={setChanges}
                    field={field}
                    id={id}
                    changes={changes}
                    type={type}
                    path={path}
                    refresh={refresh}
                    value={values}
                />
            );
        }
        default:
            return <>{field.FieldType}</>;
    }
};

export default withStyles(styles)(withEntry(withApi(Field)));