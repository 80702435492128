import * as React from 'react';
import {
    IconButton, ListItem, ListItemText, ListItemSecondaryAction, ListItemIcon, makeStyles, createStyles
} from '@material-ui/core';
import EntryIcon from '../EntryIcon';
import { Add } from '@material-ui/icons';
import SelectEntry from './SelectEntry';
import { listItemIconMinWidth } from '../../constants/stylesheet';
type EntryFieldSelectProps = {
    field: FieldDto;
    path: string;
    label?: string;
    value?: EntryDto;
    preload?: boolean;
    onCreate?: (query?: string) => void;
    change: (entry?: EntryDto) => void;
    list: (query: string) => Promise<EntryDto[]>;
};

const useStyles = makeStyles(() =>
    createStyles({
        listItem: {
            paddingLeft: 0
        },
        icon: {
            minWidth: listItemIconMinWidth
        },
        primaryText: {
            fontSize: '1.2rem'
        },
        secondaryText: {
            fontSize: '.7rem',
            color: 'black'
        },
        secondaryAction: {
            right: 4
        }
    }),
);

type Props = EntryFieldSelectProps;

const EntryFieldSelect: React.FunctionComponent<Props> = ({ field, path, ...rest }) => {

    const classes = useStyles();
    const { onCreate } = rest;
    return (
        <ListItem 
            classes={{ root: classes.listItem }}
            component='div'
            ContainerComponent='div'
        >
            <ListItemIcon classes={{ root: classes!.icon }}>
                <EntryIcon entry={{ Type: field.PropertyType }} />
            </ListItemIcon>
            <ListItemText
                primary={(
                    <SelectEntry
                        {...rest}
                        label={rest.label || field.Legend}
                    />
                    )}
            />
            {onCreate && ( 
                <ListItemSecondaryAction
                    classes={{ root: classes.secondaryAction }}
                >
                    <IconButton 
                        edge='end' 
                        aria-label='Add' 
                        size='small'
                        onClick={() => onCreate()}
                    >
                        <Add />
                    </IconButton>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};

export default EntryFieldSelect;