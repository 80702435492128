import React, { FC, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useApi } from '../@context/Api';

const useStyles = makeStyles({
  iframe: {
    width: '100%',
    display: 'block',
    border: 0,
    height: '100vh'
  },
  fields: {

  },
  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem'
  },
  label: {
  },
  button: {
    flex: '1 1 auto',

    display: 'flex',
    justifyContent: 'center',
  },
  buttonProcessing: {
    background: 'green',
    pointerEvents: 'none',
    boxShadow: '0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)'
  },
  progress: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
    color: 'white',
  }

});
export type PostFinanceFormResponse = {
  name: string;
  action: string;
  param: Object;
  amount: string;
  txid: string;
  method: string;
};

const PayByPostFinance: FC<{ invoice: InvoiceToPay, done: () => void; }> = ({ invoice, done }) => {
  const cls = useStyles();
  const { Id } = invoice;
  const { fetch } = useApi();
  const [form, setForm] = useState<PostFinanceFormResponse>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState<string>();

  const iframe = useRef<HTMLIFrameElement>(null);
  useEffect(
    () => {
      const messageRecieved = (event: MessageEvent) => {
        if (event.data === 'done') {
          done();
        }
      };
      window.addEventListener('message', messageRecieved, false);
      return window.removeEventListener('message', messageRecieved);
    },
    []
  );
  useEffect(
    () => {
      const effect = async () => {
        const requestOptions = {
          method: 'GET'
        };

        const url = `billing/postfinance//${Id}/form`;
        try {
          const result = await fetch<PostFinanceFormResponse>(url, requestOptions);
          setForm(result);
        } catch (err) {
          setError(err);
        }
      };
      effect();
    },
    [fetch, Id]
  );

  useEffect(
    () => {
      if (iframe.current && form && !formSubmitted) {
        var modalContent = iframe.current.contentWindow!.document.body;

        const tosubmit = document.createElement('form');
        modalContent.appendChild(tosubmit);

        tosubmit.action = form.action;

        if (form.method) {
          tosubmit.method = form.method;
        }
        for (var name in form.param) {
          if (!form.param.hasOwnProperty(name)) { continue; }
          const f = document.createElement('input');
          f.type = 'hidden';
          f.name = name;
          f.value = form.param[name];
          tosubmit.appendChild(f);
        }
        // form.target = '_blank';
        tosubmit.submit();
        setFormSubmitted(true);
      }
    },
    [form, iframe]
  );

  return (
    <>

      {form &&
        <iframe className={cls.iframe} ref={iframe} />
      }
      {error && error.split('\n').map((l, i) => <div key={i} className={classNames(cls.error)}>{l}</div>)}
    </>
  );
};
export default PayByPostFinance;