
import ListView from './ListView';
import { EntryProvider } from '../@context/Entry';
import SubscriptionCreator from './Creators/SubscriptionCreator';
import DefaultCreator from './Creators/DefaultCreator';
import React, { FC } from 'react';
import PreviewRenderer from './PreviewRenderer';

type ListContentProps = {
    type: string;
    baseURL: string;
};

type Props = ListContentProps;

const ListContent: FC<Props> = (props) => {

    const { type, baseURL } = props;

    return (
        <EntryProvider key={type} baseURL={baseURL}>
            <ListView type={type} itemRenderer={(entry: IEntry) => <PreviewRenderer entry={entry} />}>
                {type === 'subscription' &&
                    <SubscriptionCreator />
                }
                {type !== 'subscription' &&
                    <DefaultCreator type={type} />
                }
            </ListView>
        </EntryProvider>
    );
};

export default ListContent;