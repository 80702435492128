import * as React from 'react';
import { Subtract } from 'utility-types';
import { handleApiResponse } from '../@utils/auth-header';
import { useContext } from 'react';

export const LOCALSTORAGE_KEY = 'Api';

const storedUserJson = localStorage.getItem(LOCALSTORAGE_KEY);

export const InitUser = storedUserJson ? JSON.parse(storedUserJson) as Client : undefined;

type DBResult = {
    entry: EntryDto;
    changes: ChangeResult
};
type Api = {

    fetching?: boolean;

    user?: Client;

    loading?: boolean;
    lastChange?: ChangeResult;
    getAll: (type: string, query?: string) => Promise<EntryDto[]>;
    countAll: (type: string, query?: string) => Promise<number>;

    addCount: (type: string, query?: string) => void;
    counts: { [id: string]: number };

    fetch: <TResult>(endpoint: string, options?: RequestInit) => Promise<TResult>;

    get: (type: string, id: string) => Promise<EntryDto>;

    save: (type: string, id: string, changes: Partial<EntryDto>) => Promise<EntryDto>;
    deleteEntry: (type: string, id: string) => Promise<EntryDto>;
    preview: (type: string, id: string, changes: Partial<EntryDto>) => Promise<EntryDto>;
    create: (type: string, parent?: Dto, query?: string, changes?: Partial<EntryDto>) => Promise<EntryDto>;
    createShipment: (type: string, parent?: Dto, query?: string, changes?: Partial<EntryDto>) => Promise<EntryDto>;
    login: (username: string, password: string) => Promise<Client>;
    logout: () => Promise<void>;
};

const initValue: Api = {

    getAll: 0 as never,
    countAll: 0 as never,
    addCount: 0 as never,
    counts: 0 as never,
    get: 0 as never,
    preview: 0 as never,
    fetch: 0 as never,
    save: 0 as never,
    deleteEntry: 0 as never,
    loading: true,
    create: 0 as never,
    createShipment: 0 as never,
    login: 0 as never,
    logout: 0 as never
};

export const ApiContext = React.createContext<Api>(initValue);

type ApiProviderProps = { apiUrl: string };
export class ApiProvider extends React.Component<ApiProviderProps, Api> {
    static GetCountId = (type: string, query?: string) => `${type}:${query || '*'}`;
    public constructor(props: ApiProviderProps) {
        super(props);

        this.state = {
            get: this._get.bind(this),
            getAll: this._getAll.bind(this),
            countAll: this._countAll.bind(this),
            addCount: this._addCount.bind(this),
            counts: {},
            user: InitUser,
            loading: true,
            save: this._save.bind(this),
            deleteEntry: this._delete.bind(this),
            preview: this._preview.bind(this),
            fetch: this._fetch.bind(this),
            create: this._create.bind(this),
            createShipment: this._createShipment.bind(this),
            login: this._login.bind(this),
            logout: this._logout.bind(this)
        };
    }

    render() {
        return (
            <ApiContext.Provider value={this.state}>
                {this.props.children}
            </ApiContext.Provider>
        );
    }
    async componentDidMount() {
        const { user } = this.state;
        if (!user) {
            this.setState({ loading: false });
        } else {
            const { apiUrl } = this.props;
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            try {
                const touch = await fetch(`${apiUrl}/entries`, requestOptions);
                this.setState(() => ({
                    loading: false,
                    user: (touch.ok && user) || undefined
                }));
            } catch {
                this.setState(() => ({
                    loading: false,
                    user: undefined
                }));
            }

        }

    }
    private async _getAll(type: string, query?: string): Promise<IEntry[]> {
        const { apiUrl } = this.props;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const url = `${apiUrl}/entries/${type}${query ? `?query=${encodeURIComponent(query)}` : ''}`;
        const response = await fetch(url, requestOptions);
        const entries = await handleApiResponse<IEntry[]>(response);

        return entries;
    }
    private async _countAll(type: string, query?: string): Promise<number> {
        const { apiUrl } = this.props;
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        const url = `${apiUrl}/entries/${type}/count${query ? `?query=${encodeURIComponent(query)}` : ''}`;

        const response = await fetch(
            url,
            requestOptions);

        const count = await handleApiResponse<number>(response);

        return count;
    }

    private async _addCount(type: string, query?: string) {
        const id = ApiProvider.GetCountId(type, query);
        this._countAll(type, query).then(
            count => this.setState(({ counts }) => ({ counts: { ...counts, [id]: count } }))
        );
    }
    private _refreshCounts(type: string) {
        const torefresh = Object.keys(this.state.counts)
            .filter(k => k.indexOf(type) === 0)
            .map(k => k.split(':'))
            .map(k => ({ id: k[0], query: k[1] === '*' ? undefined : k[1] }));

        torefresh.forEach(r => this._addCount(r.id, r.query));
    }

    private async _fetch<TResult>(endpoint: string, options?: RequestInit) {
        const { apiUrl } = this.props;
        const requestOptions: RequestInit = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            ...options
        };

        const response = await fetch(`${apiUrl}/${endpoint}`, requestOptions);
        const entry = await handleApiResponse<TResult>(response);

        return entry;
    }

    private async _get(type: string, id: string) {
        const options = {
            method: 'GET'
        };

        return await this._fetch<EntryDto>(`entries/${type}/${id}`, options);

    }
    private async _save(type: string, id: string, changes: {}) {
        const patchDocument = Object.keys(changes).map(k => ({ op: 'replace', path: `/${k}`, value: changes[k] }));
        const options: RequestInit = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patchDocument)
        };

        const dBResult = await this._fetch<DBResult>(`entries/${type}/${id}`, options);
        this._refreshCounts(type);
        this.setState(() => ({ lastChange: dBResult.changes }));
        return dBResult.entry;
    }

    private async _delete(type: string, id: string) {
        const options: RequestInit = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        };

        const dBResult = await this._fetch<DBResult>(`entries/${type}/${id}`, options);
        this._refreshCounts(type);
        this.setState(() => ({ lastChange: dBResult.changes }));
        return dBResult.entry;
    }

    private async _preview(type: string, id: string, changes: {}) {
        const patchDocument = Object.keys(changes).map(k => ({ op: 'replace', path: `/${k}`, value: changes[k] }));
        const options: RequestInit = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(patchDocument)
        };
        const entry = await this._fetch<EntryDto>(`entries/${type}/${id}/preview`, options);
        this._refreshCounts(type);
        return entry;
    }

    private async _create(type: string, parent?: Dto, query?: string, changes?: Partial<EntryDto>) {
        const patchDocument = changes &&
            Object.keys(changes).map(k => ({ op: 'replace', path: `/${k}`, value: changes[k] }));

        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: patchDocument && JSON.stringify(patchDocument)
        };

        const queries: string[] = [];
        if (parent) {
            queries.push(`parentId=${parent.Id}`);
            queries.push(`parentType=${parent.Type}`);
        }

        if (query) {
            queries.push(`query=${encodeURIComponent(query)}`);
        }

        const url = `entries/${type}${queries.length ? `?${queries.join('&')}` : ''}`;
        const dBResult = await this._fetch<DBResult>(url, options);
        this._refreshCounts(type);
        this.setState(() => ({
            lastChange: dBResult.changes
        }));

        return dBResult.entry;
    }

    private async _createShipment(type: string, parent?: Dto, query?: string, changes?: Partial<EntryDto>) {
        const patchDocument = changes &&
            Object.keys(changes).map(k => ({ op: 'replace', path: `/${k}`, value: changes[k] }));

        const options: RequestInit = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: patchDocument && JSON.stringify(patchDocument)
        };

        const queries: string[] = [];
        if (parent) {
            queries.push(`parentId=${parent.Id}`);
            queries.push(`parentType=${parent.Type}`);
        }

        if (query) {
            queries.push(`query=${encodeURIComponent(query)}`);
        }
        
        const url = `entries/shipment/${type}${queries.length ? `?${queries.join('&')}` : ''}`;
        const dBResult = await this._fetch<DBResult>(url, options);
        this._refreshCounts(type);
        this.setState(() => ({
            lastChange: dBResult.changes
        }));

        return dBResult.entry;
    }

    private async _login(username: string, password: string): Promise<Client> {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({ Email: username, Password: password })
        };

        const user = await this._fetch<Client>(`account/signin`, requestOptions);

        if (!user) {
            this.setState(() => ({ fetching: false, user: undefined }));
            throw new Error('impossible to log in');
        }
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(user));
        this.setState(() => ({ fetching: false, user }));
        return user;
    }

    private async _logout(): Promise<void> {

        const requestOptions = {
            method: 'POST',
        };

        await this._fetch(`account/signout`, requestOptions);

        localStorage.removeItem(LOCALSTORAGE_KEY);
        this.setState(() => ({ fetching: false, user: undefined }));
    }

}

export type InjectedApiProps = { apiCtx: Api };
export const withApi =
    <OriginalProps extends InjectedApiProps>(
        Component: React.ComponentType<OriginalProps>
    ): React.FunctionComponent<Subtract<OriginalProps, InjectedApiProps>> => {

        return (props: Subtract<OriginalProps, InjectedApiProps>) => {
            return (
                <ApiContext.Consumer>
                    {(apiCtx) => <Component {...{ ...(props as object), apiCtx } as OriginalProps} />}
                </ApiContext.Consumer>
            );
        };
    };

export const useApi = () => useContext(ApiContext);