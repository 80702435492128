import * as React from 'react';
import { FormControl, FormGroup, FormControlLabel, Switch } from '@material-ui/core';

type Props = {
    field: FieldDto;
    value: boolean;
    changes: Partial<EntryDto>;
    setChanges: (changes: Partial<EntryDto>) => void;
};

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         underline: {
//             '&:before': {
//                 borderBottomColor: '#3f51b5'
//             }
//         },
//         label: {
//             color: '#858585'
//         },
//         labelNovalue: {
//             color: '#cccccc'
//         },
//         labelUnsaved: {
//             color: '#3f51b5',
//             fontWeight: 600
//         },
//     }));

const SwitchField: React.FunctionComponent<Props> = (props) => {
    // const classes = useStyles();

    const {
        value, field, setChanges, changes
    } = props;

    return (
        <FormControl
            component='fieldset'
            fullWidth={true}
        >
            <FormGroup aria-label='position' row={true}>
                <FormControlLabel
                    value={field.Legend}
                    control={(
                        <Switch
                            checked={!!value}
                            onChange={(e) => setChanges({ ...changes, [field.PropertyName]: e.target.checked })}
                            value={field.PropertyName}
                            color='primary'
                        />
                    )}
                    label={field.Legend}
                    labelPlacement='end'
                />
            </FormGroup>
        </FormControl>

    );
};

export default SwitchField;